<template>
  <div class="basic-information">
    <vs-row class="title">
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
        <h3>
          {{titleForm}}
        </h3>
      </vs-col>
    </vs-row>
    <br>
    <vs-row>
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
        Cuéntanos sobre ti.
      </vs-col>
    </vs-row>
    <br>
    <form>
      <div class="vx-row">              
        <div class="vx-col md:w-1/3 w-full">
          <label v-bind:style="colorLabel(errors.has('first_name'))">Nombre Aspirante*</label>
          <vs-input size="large" v-validate="'required'" placeholder="Nombre aspirante" name="first_name" v-model="candidate.first_name" />          
        </div>
        <div class="vx-col md:w-1/3 w-full">
          <label v-bind:style="colorLabel(errors.has('last_name'))">Apellidos aspirante*</label>
          <vs-input size="large" v-validate="'required'" placeholder="Apellidos aspirante" name="last_name" v-model="candidate.last_name" />
        </div>
        <div class="vx-col md:w-1/3 w-full">
          <label v-bind:style="colorLabel(errors.has('birth_date'))">Fecha de nacimiento*</label>
          <datepicker placeholder="Fecha de nacimiento" v-validate="'required'" name="birth_date" v-model="candidate.birth_date"></datepicker>
        </div>
      </div>      

      <div class="vx-row">              
        <div class="vx-col md:w-1/5 w-full">
          <label v-bind:style="colorLabel(errors.has('gender'))">Género*</label>
          <vs-select v-model="candidate.gender" class="w-full select-large mt-5 select-simple" name="gender" v-validate="'required'">
            <vs-select-item :key="index" :value="item.id" :text="item.label" v-for="(item,index) in genders" />
          </vs-select>                  
        </div>
        <div class="vx-col md:w-1/5 w-full">
          <label v-bind:style="colorLabel(errors.has('civil_status'))">Estado civil*</label>
          <vs-select v-model="candidate.civil_status" class="w-full select-large mt-5 select-simple" name="civil_status" v-validate="'required'">
            <vs-select-item :key="index" :value="item.id" :text="item.label" v-for="(item,index) in civilTypes" />
          </vs-select>                  
        </div>
        <div class="vx-col md:w-1/5 w-full">
          <label for="">Libreta militar #</label>
          <vs-input size="large" placeholder="Libreta militar #" name="military_card_number" v-model="candidate.military_card_number" />          
        </div>                              
        <div class="vx-col md:w-1/5 w-full">
          <label for="">Clase Libreta militar</label>
          <vs-input size="large" placeholder="Clase Libreta militar" name="military_card_type" v-model="candidate.military_card_type" />          
        </div>
        <div class="vx-col md:w-1/5 w-full">
          <label v-bind:style="colorLabel(errors.has('blood_type'))">Grupo sanguíneo*</label>
          <vs-select v-model="candidate.blood_type" class="w-full select-large mt-5 select-simple" name="bloodTypes" v-validate="'required'">
            <vs-select-item :key="index" :value="item.id" :text="item.label" v-for="(item,index) in bloodTypes" />
          </vs-select>                  
        </div>        
      </div>            

      <div class="vx-row">              
        <div class="vx-col md:w-1/5 w-full">
          <label v-bind:style="colorLabel(errors.has('current_activity'))">Actividad actual*</label>
          <vs-input size="large" v-validate="'required'" placeholder="Actividad actual" name="current_activity" v-model="candidate.current_activity" />          
        </div>
        <div class="vx-col md:w-1/5 w-full">
          <label for="">Placa</label>
          <vs-input size="large" placeholder="Placa" name="licence_plate" v-model="candidate.licence_plate" />
        </div>
        <div class="vx-col md:w-1/5 w-full">
          <label v-bind:style="colorLabel(errors.has('city_id'))">Ciudad de residencia*</label>
          <v-select v-model="candidate.city_id" :options="cities()" v-validate="'required'" name="city_id" ></v-select>                              
        </div>                              
        <div class="vx-col md:w-1/5 w-full">
          <label v-bind:style="colorLabel(errors.has('location'))">Localidad*</label>
          <vs-input size="large" v-validate="'required'" placeholder="Localidad" name="location" v-model="candidate.location" />
        </div>
        <div class="vx-col md:w-1/5 w-full">
          <label v-bind:style="colorLabel(errors.has('address'))">Dirección*</label>
          <vs-input size="large" v-validate="'required'" placeholder="Dirección" name="address" v-model="candidate.address" />
        </div>        
      </div>                  

      <div class="vx-row">
        <div class="vx-col md:w-1/4 w-full">
          <label v-bind:style="colorLabel(errors.has('document_type'))">Tipo de documento*</label>
          <vs-select v-model="candidate.document_type" class="w-full select-large mt-5 select-simple" name="document_type" v-validate="'required'">
            <vs-select-item :key="index" :value="item.id" :text="item.label" v-for="(item,index) in idTypes" />
          </vs-select>                  
        </div>                              
        <div class="vx-col md:w-1/4 w-full">
          <label v-bind:style="colorLabel(errors.has('person_id'))">Cédula de ciudadanía*</label>
          <vs-input size="large" v-validate="'required'" placeholder="Cédula de ciudadanía" name="person_id" v-model="candidate.person_id" />
        </div>                      
        <div class="vx-col md:w-1/4 w-full">
          <label v-bind:style="colorLabel(errors.has('email'))">Email*</label>
          <vs-input size="large" v-validate="'required'" placeholder="Email" name="email" v-model="candidate.email" />
          <span class="text-danger message-field-require"  v-show="errors.has('candidate.email')">*</span>
        </div>
        <div class="vx-col md:w-1/4 w-full">
          <label v-bind:style="colorLabel(errors.has('phone'))">Teléfono*</label>
          <vs-input size="large" v-validate="'required'" placeholder="Teléfono" name="phone" v-model="candidate.phone" />
          <span class="text-danger message-field-require"  v-show="errors.has('candidate.phone')">*</span>
        </div>        
      </div>                        

      <!-- button sumit -->
      <vs-row class="row-btn-save">
        <vs-col vs-offset="10" vs-type="flex" vs-justify="center" vs-align="center" vs-w="2">
          <vs-button type="filled" @click.prevent="submitForm" class="mt-5 block">Guardar</vs-button>
        </vs-col>
      </vs-row>      
    </form>    
  </div>
</template>

<script>
import vSelect from 'vue-select';
import Datepicker from 'vuejs-datepicker';
import cities from '../../../mixins/cities.js'
import api from '../../../mixins/api.js'
export default {
  mixins: [cities, api],
  props: {
    candidate: Object
  },    
  data(){
    return{
      idTypes: [
        {id: null, label: 'Seleccione una opción'},
        {id: 1, label: 'Cédula'},
        {id: 2, label: 'Tarjeta de identidad'},
        {id: 3, label: 'Pasaporte'},
        {id: 4, label: 'Cédula de extranjería'},
      ],        
      genders: [
        {id: null, label: 'Seleccione una opción'},
        {id: 0, label: 'Femenino'},
        {id: 1, label: 'Masculino'}
      ],
      civilTypes: [
        {id: null, label: 'Seleccione una opción'},
        {id: 0, label: 'Soltero/a'},
        {id: 1, label: 'Casado/a'},
        {id: 3, label: 'Viudo/a'},
        {id: 4, label: 'Unión libre'}
      ],
      bloodTypes: [
        {id: null, label: 'Seleccione una opción'},
        {id: 0, label: 'O-'},
        {id: 0, label: 'O+'},
        {id: 0, label: 'A-'},
        {id: 0, label: 'A+'},
        {id: 0, label: 'B-'},
        {id: 0, label: 'B+'},
        {id: 0, label: 'AB-'},
        {id: 0, label: 'AB+'}
      ],      
      titleForm: 'Información básica',
      // candidate: {

      // }
    }
  },
  methods: {
    colorLabel(errorLabel){
      var cadenaColor = 'color: black';
      if(errorLabel){
        cadenaColor = 'color: red';
      }
      return cadenaColor;
    },
    submitForm() {
      this.$validator.validateAll().then(result => {
        if(result) {
          var candidate_id = this.candidate.id
          console.log("candidate_id ", candidate_id)
          var path = "/api/v1/candidates/"+candidate_id
          this.requestPut(this.url()+path, this.candidate, this.headers()).then(response => {
            console.log(response)
            this.$vs.notify({color:'success',title:'Informacion  básica guardada',text:'Se ha guardado la información', position: 'bottom-left'})          
          }, (err) => {
            console.log('err users -> ' +  JSON.stringify(err))
            this.$vs.notify({color:'danger',title:'Ups',text:'No se guardo la información básica', position: 'bottom-left'})      
          })          
        }else{
          // form have errors
        }
      })
    }        
  },
  created() {
    // console.log("this.candidate ", JSON.stringify(this.candidate))
    // this.candidate.first_name = this.candidate.first_name
    // this.candidate.last_name = this.candidate.last_name
    // this.candidate.email = this.candidate.email
    // this.candidate.phone = this.candidate.phone
    // console.log("this.candidate.first_name ", this.candidate.first_name)
    // console.log("this.candidate.last_name ", this.candidate.last_name)
    // console.log("this.candidate.email ", this.candidate.email)
    // console.log("this.candidate.phone ", this.candidate.phone)
  },      
  components: {
    vSelect,
    Datepicker
  }

}
</script>

<style>
  /* .basic-information{
    background: #ffffff;
    margin-left: 5rem;
    margin-right: 5rem;
    border-radius: 10px;
    height: 42rem;
  } */
  .title{
    padding-top: 1rem;
  }
  .row-btn-save{
    padding-bottom: 1rem;
  }
  .message-field-require{
    font-size: 2rem !important;
  }
  .vs__search{
    height: 38px !important
  }
  .select-simple{
    margin-top: 0rem !important;
  }
  .select-large input.vs-select--input{
    height: 49px !important;
  }
  .vdp-datepicker input{
    height: 49px !important;
  }
  .vx-row{
    margin: 0;
    padding-top: 1rem;
  }
  .vs-con-input-label{
    width: 100%;
  }
  .vdp-datepicker input{
    width: 100%;
  }
</style>