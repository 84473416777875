var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "basic-information" },
    [
      _c(
        "vs-row",
        { staticClass: "title" },
        [
          _c(
            "vs-col",
            {
              attrs: {
                "vs-type": "flex",
                "vs-justify": "center",
                "vs-align": "center",
                "vs-w": "6",
              },
            },
            [
              _c("h3", [
                _vm._v("\n        " + _vm._s(_vm.titleForm) + "\n      "),
              ]),
            ]
          ),
        ],
        1
      ),
      _c("br"),
      _c(
        "vs-row",
        [
          _c(
            "vs-col",
            {
              attrs: {
                "vs-type": "flex",
                "vs-justify": "center",
                "vs-align": "center",
                "vs-w": "12",
              },
            },
            [_vm._v("\n      Cuéntanos sobre ti.\n    ")]
          ),
        ],
        1
      ),
      _c("br"),
      _c(
        "form",
        [
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-1/3 w-full" },
              [
                _c(
                  "label",
                  { style: _vm.colorLabel(_vm.errors.has("first_name")) },
                  [_vm._v("Nombre Aspirante*")]
                ),
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  attrs: {
                    size: "large",
                    placeholder: "Nombre aspirante",
                    name: "first_name",
                  },
                  model: {
                    value: _vm.candidate.first_name,
                    callback: function ($$v) {
                      _vm.$set(_vm.candidate, "first_name", $$v)
                    },
                    expression: "candidate.first_name",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/3 w-full" },
              [
                _c(
                  "label",
                  { style: _vm.colorLabel(_vm.errors.has("last_name")) },
                  [_vm._v("Apellidos aspirante*")]
                ),
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  attrs: {
                    size: "large",
                    placeholder: "Apellidos aspirante",
                    name: "last_name",
                  },
                  model: {
                    value: _vm.candidate.last_name,
                    callback: function ($$v) {
                      _vm.$set(_vm.candidate, "last_name", $$v)
                    },
                    expression: "candidate.last_name",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/3 w-full" },
              [
                _c(
                  "label",
                  { style: _vm.colorLabel(_vm.errors.has("birth_date")) },
                  [_vm._v("Fecha de nacimiento*")]
                ),
                _c("datepicker", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  attrs: {
                    placeholder: "Fecha de nacimiento",
                    name: "birth_date",
                  },
                  model: {
                    value: _vm.candidate.birth_date,
                    callback: function ($$v) {
                      _vm.$set(_vm.candidate, "birth_date", $$v)
                    },
                    expression: "candidate.birth_date",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-1/5 w-full" },
              [
                _c(
                  "label",
                  { style: _vm.colorLabel(_vm.errors.has("gender")) },
                  [_vm._v("Género*")]
                ),
                _c(
                  "vs-select",
                  {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    staticClass: "w-full select-large mt-5 select-simple",
                    attrs: { name: "gender" },
                    model: {
                      value: _vm.candidate.gender,
                      callback: function ($$v) {
                        _vm.$set(_vm.candidate, "gender", $$v)
                      },
                      expression: "candidate.gender",
                    },
                  },
                  _vm._l(_vm.genders, function (item, index) {
                    return _c("vs-select-item", {
                      key: index,
                      attrs: { value: item.id, text: item.label },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/5 w-full" },
              [
                _c(
                  "label",
                  { style: _vm.colorLabel(_vm.errors.has("civil_status")) },
                  [_vm._v("Estado civil*")]
                ),
                _c(
                  "vs-select",
                  {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    staticClass: "w-full select-large mt-5 select-simple",
                    attrs: { name: "civil_status" },
                    model: {
                      value: _vm.candidate.civil_status,
                      callback: function ($$v) {
                        _vm.$set(_vm.candidate, "civil_status", $$v)
                      },
                      expression: "candidate.civil_status",
                    },
                  },
                  _vm._l(_vm.civilTypes, function (item, index) {
                    return _c("vs-select-item", {
                      key: index,
                      attrs: { value: item.id, text: item.label },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/5 w-full" },
              [
                _c("label", { attrs: { for: "" } }, [
                  _vm._v("Libreta militar #"),
                ]),
                _c("vs-input", {
                  attrs: {
                    size: "large",
                    placeholder: "Libreta militar #",
                    name: "military_card_number",
                  },
                  model: {
                    value: _vm.candidate.military_card_number,
                    callback: function ($$v) {
                      _vm.$set(_vm.candidate, "military_card_number", $$v)
                    },
                    expression: "candidate.military_card_number",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/5 w-full" },
              [
                _c("label", { attrs: { for: "" } }, [
                  _vm._v("Clase Libreta militar"),
                ]),
                _c("vs-input", {
                  attrs: {
                    size: "large",
                    placeholder: "Clase Libreta militar",
                    name: "military_card_type",
                  },
                  model: {
                    value: _vm.candidate.military_card_type,
                    callback: function ($$v) {
                      _vm.$set(_vm.candidate, "military_card_type", $$v)
                    },
                    expression: "candidate.military_card_type",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/5 w-full" },
              [
                _c(
                  "label",
                  { style: _vm.colorLabel(_vm.errors.has("blood_type")) },
                  [_vm._v("Grupo sanguíneo*")]
                ),
                _c(
                  "vs-select",
                  {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    staticClass: "w-full select-large mt-5 select-simple",
                    attrs: { name: "bloodTypes" },
                    model: {
                      value: _vm.candidate.blood_type,
                      callback: function ($$v) {
                        _vm.$set(_vm.candidate, "blood_type", $$v)
                      },
                      expression: "candidate.blood_type",
                    },
                  },
                  _vm._l(_vm.bloodTypes, function (item, index) {
                    return _c("vs-select-item", {
                      key: index,
                      attrs: { value: item.id, text: item.label },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-1/5 w-full" },
              [
                _c(
                  "label",
                  { style: _vm.colorLabel(_vm.errors.has("current_activity")) },
                  [_vm._v("Actividad actual*")]
                ),
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  attrs: {
                    size: "large",
                    placeholder: "Actividad actual",
                    name: "current_activity",
                  },
                  model: {
                    value: _vm.candidate.current_activity,
                    callback: function ($$v) {
                      _vm.$set(_vm.candidate, "current_activity", $$v)
                    },
                    expression: "candidate.current_activity",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/5 w-full" },
              [
                _c("label", { attrs: { for: "" } }, [_vm._v("Placa")]),
                _c("vs-input", {
                  attrs: {
                    size: "large",
                    placeholder: "Placa",
                    name: "licence_plate",
                  },
                  model: {
                    value: _vm.candidate.licence_plate,
                    callback: function ($$v) {
                      _vm.$set(_vm.candidate, "licence_plate", $$v)
                    },
                    expression: "candidate.licence_plate",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/5 w-full" },
              [
                _c(
                  "label",
                  { style: _vm.colorLabel(_vm.errors.has("city_id")) },
                  [_vm._v("Ciudad de residencia*")]
                ),
                _c("v-select", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  attrs: { options: _vm.cities(), name: "city_id" },
                  model: {
                    value: _vm.candidate.city_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.candidate, "city_id", $$v)
                    },
                    expression: "candidate.city_id",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/5 w-full" },
              [
                _c(
                  "label",
                  { style: _vm.colorLabel(_vm.errors.has("location")) },
                  [_vm._v("Localidad*")]
                ),
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  attrs: {
                    size: "large",
                    placeholder: "Localidad",
                    name: "location",
                  },
                  model: {
                    value: _vm.candidate.location,
                    callback: function ($$v) {
                      _vm.$set(_vm.candidate, "location", $$v)
                    },
                    expression: "candidate.location",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/5 w-full" },
              [
                _c(
                  "label",
                  { style: _vm.colorLabel(_vm.errors.has("address")) },
                  [_vm._v("Dirección*")]
                ),
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  attrs: {
                    size: "large",
                    placeholder: "Dirección",
                    name: "address",
                  },
                  model: {
                    value: _vm.candidate.address,
                    callback: function ($$v) {
                      _vm.$set(_vm.candidate, "address", $$v)
                    },
                    expression: "candidate.address",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-1/4 w-full" },
              [
                _c(
                  "label",
                  { style: _vm.colorLabel(_vm.errors.has("document_type")) },
                  [_vm._v("Tipo de documento*")]
                ),
                _c(
                  "vs-select",
                  {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    staticClass: "w-full select-large mt-5 select-simple",
                    attrs: { name: "document_type" },
                    model: {
                      value: _vm.candidate.document_type,
                      callback: function ($$v) {
                        _vm.$set(_vm.candidate, "document_type", $$v)
                      },
                      expression: "candidate.document_type",
                    },
                  },
                  _vm._l(_vm.idTypes, function (item, index) {
                    return _c("vs-select-item", {
                      key: index,
                      attrs: { value: item.id, text: item.label },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/4 w-full" },
              [
                _c(
                  "label",
                  { style: _vm.colorLabel(_vm.errors.has("person_id")) },
                  [_vm._v("Cédula de ciudadanía*")]
                ),
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  attrs: {
                    size: "large",
                    placeholder: "Cédula de ciudadanía",
                    name: "person_id",
                  },
                  model: {
                    value: _vm.candidate.person_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.candidate, "person_id", $$v)
                    },
                    expression: "candidate.person_id",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/4 w-full" },
              [
                _c(
                  "label",
                  { style: _vm.colorLabel(_vm.errors.has("email")) },
                  [_vm._v("Email*")]
                ),
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  attrs: { size: "large", placeholder: "Email", name: "email" },
                  model: {
                    value: _vm.candidate.email,
                    callback: function ($$v) {
                      _vm.$set(_vm.candidate, "email", $$v)
                    },
                    expression: "candidate.email",
                  },
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("candidate.email"),
                        expression: "errors.has('candidate.email')",
                      },
                    ],
                    staticClass: "text-danger message-field-require",
                  },
                  [_vm._v("*")]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/4 w-full" },
              [
                _c(
                  "label",
                  { style: _vm.colorLabel(_vm.errors.has("phone")) },
                  [_vm._v("Teléfono*")]
                ),
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  attrs: {
                    size: "large",
                    placeholder: "Teléfono",
                    name: "phone",
                  },
                  model: {
                    value: _vm.candidate.phone,
                    callback: function ($$v) {
                      _vm.$set(_vm.candidate, "phone", $$v)
                    },
                    expression: "candidate.phone",
                  },
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("candidate.phone"),
                        expression: "errors.has('candidate.phone')",
                      },
                    ],
                    staticClass: "text-danger message-field-require",
                  },
                  [_vm._v("*")]
                ),
              ],
              1
            ),
          ]),
          _c(
            "vs-row",
            { staticClass: "row-btn-save" },
            [
              _c(
                "vs-col",
                {
                  attrs: {
                    "vs-offset": "10",
                    "vs-type": "flex",
                    "vs-justify": "center",
                    "vs-align": "center",
                    "vs-w": "2",
                  },
                },
                [
                  _c(
                    "vs-button",
                    {
                      staticClass: "mt-5 block",
                      attrs: { type: "filled" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.submitForm.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("Guardar")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }