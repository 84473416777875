var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "basic-information" },
    [
      _c(
        "vs-row",
        { staticClass: "title" },
        [
          _c(
            "vs-col",
            {
              attrs: {
                "vs-type": "flex",
                "vs-justify": "center",
                "vs-align": "center",
                "vs-w": "6",
              },
            },
            [
              _c("h3", [
                _vm._v("\n        " + _vm._s(_vm.titleForm) + "\n      "),
              ]),
            ]
          ),
        ],
        1
      ),
      _c("br"),
      _c(
        "vs-row",
        [
          _c(
            "vs-col",
            {
              attrs: {
                "vs-type": "flex",
                "vs-justify": "center",
                "vs-align": "center",
                "vs-w": "12",
              },
            },
            [
              _vm._v(
                "\n      Ingresa los datos de tus últimas dos experiencias laborales.\n    "
              ),
            ]
          ),
        ],
        1
      ),
      _c("br"),
      _c(
        "form",
        [
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-1/4 w-full" },
              [
                _c(
                  "label",
                  { style: _vm.colorLabel(_vm.errors.has("company_name")) },
                  [_vm._v("Empresa*")]
                ),
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  attrs: {
                    size: "large",
                    placeholder: "Empresa",
                    name: "company_name",
                  },
                  model: {
                    value: _vm.form.company_name,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "company_name", $$v)
                    },
                    expression: "form.company_name",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/4 w-full" },
              [
                _c(
                  "label",
                  { style: _vm.colorLabel(_vm.errors.has("charge")) },
                  [_vm._v("Cargo desempeñado*")]
                ),
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  attrs: {
                    size: "large",
                    placeholder: "Cargo desempeñado",
                    name: "charge",
                  },
                  model: {
                    value: _vm.form.charge,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "charge", $$v)
                    },
                    expression: "form.charge",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/4 w-full" },
              [
                _c(
                  "label",
                  { style: _vm.colorLabel(_vm.errors.has("start_at")) },
                  [_vm._v("Fecha inicial*")]
                ),
                _c("datepicker", {
                  attrs: { placeholder: "Fecha inicial", name: "start_at" },
                  model: {
                    value: _vm.form.start_at,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "start_at", $$v)
                    },
                    expression: "form.start_at",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/4 w-full" },
              [
                _c(
                  "label",
                  { style: _vm.colorLabel(_vm.errors.has("leave_at")) },
                  [_vm._v("Fecha final*")]
                ),
                _c("datepicker", {
                  attrs: { placeholder: "Fecha final", name: "leave_at" },
                  model: {
                    value: _vm.form.leave_at,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "leave_at", $$v)
                    },
                    expression: "form.leave_at",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-1/4 w-full" },
              [
                _c(
                  "label",
                  { style: _vm.colorLabel(_vm.errors.has("contact_name")) },
                  [_vm._v("Nombre contacto*")]
                ),
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  attrs: {
                    size: "large",
                    placeholder: "Nombre contacto",
                    name: "contact_name",
                  },
                  model: {
                    value: _vm.form.contact_name,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "contact_name", $$v)
                    },
                    expression: "form.contact_name",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/4 w-full" },
              [
                _c(
                  "label",
                  { style: _vm.colorLabel(_vm.errors.has("contact_charge")) },
                  [_vm._v("Cargo del contacto contacto*")]
                ),
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  attrs: {
                    size: "large",
                    placeholder: "Cargo del contacto",
                    name: "contact_charge",
                  },
                  model: {
                    value: _vm.form.contact_charge,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "contact_charge", $$v)
                    },
                    expression: "form.contact_charge",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/4 w-full" },
              [
                _c(
                  "label",
                  {
                    style: _vm.colorLabel(_vm.errors.has("contact_telephone")),
                  },
                  [_vm._v("Teléfono del contacto*")]
                ),
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  attrs: {
                    size: "large",
                    placeholder: "Teléfono del contacto",
                    name: "contact_telephone",
                  },
                  model: {
                    value: _vm.form.contact_telephone,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "contact_telephone", $$v)
                    },
                    expression: "form.contact_telephone",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/4 w-full" },
              [
                _c(
                  "label",
                  { style: _vm.colorLabel(_vm.errors.has("salary")) },
                  [_vm._v("Email*")]
                ),
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  attrs: {
                    size: "large",
                    placeholder: "Email",
                    name: "salary",
                  },
                  model: {
                    value: _vm.form.email,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "email", $$v)
                    },
                    expression: "form.email",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-1/4 w-full" },
              [
                _c(
                  "label",
                  { style: _vm.colorLabel(_vm.errors.has("salary")) },
                  [_vm._v("Última asignación salarial*")]
                ),
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  attrs: {
                    size: "large",
                    placeholder: "Última asignación salarial",
                    name: "salary",
                  },
                  model: {
                    value: _vm.form.salary,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "salary", $$v)
                    },
                    expression: "form.salary",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/4 w-full" },
              [
                _c(
                  "label",
                  { style: _vm.colorLabel(_vm.errors.has("contract")) },
                  [_vm._v("Tipo de contrato*")]
                ),
                _c(
                  "vs-select",
                  {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    staticClass: "w-full select-large mt-5 select-simple",
                    attrs: { name: "contract" },
                    model: {
                      value: _vm.form.contract,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "contract", $$v)
                      },
                      expression: "form.contract",
                    },
                  },
                  _vm._l(_vm.typeContracts, function (item, index) {
                    return _c("vs-select-item", {
                      key: index,
                      attrs: { value: item.id, text: item.label },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/4 w-full" },
              [
                _c(
                  "label",
                  { style: _vm.colorLabel(_vm.errors.has("calls")) },
                  [_vm._v("Llamados de atención*")]
                ),
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  attrs: {
                    type: "number",
                    size: "large",
                    placeholder: "Llamados de atención",
                    name: "calls",
                  },
                  model: {
                    value: _vm.form.calls,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "calls", $$v)
                    },
                    expression: "form.calls",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/4 w-full" },
              [
                _c(
                  "label",
                  { style: _vm.colorLabel(_vm.errors.has("retirement")) },
                  [_vm._v("Motivo de retiro*")]
                ),
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  attrs: {
                    size: "large",
                    placeholder: "Motivo de retiro",
                    name: "retirement",
                  },
                  model: {
                    value: _vm.form.retirement,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "retirement", $$v)
                    },
                    expression: "form.retirement",
                  },
                }),
              ],
              1
            ),
          ]),
          _c(
            "vs-row",
            { staticClass: "row-btn-save" },
            [
              _c(
                "vs-col",
                {
                  attrs: {
                    "vs-offset": "10",
                    "vs-type": "flex",
                    "vs-justify": "center",
                    "vs-align": "center",
                    "vs-w": "2",
                  },
                },
                [
                  _c(
                    "vs-button",
                    {
                      staticClass: "mt-5 block",
                      attrs: { type: "filled" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.submitForm.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("Guardar")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "vx-row" }, [
        _c("div", { staticClass: "vx-col w-full lg:w-1/1 mb-base" }, [
          _c(
            "table",
            { staticClass: "table-references" },
            [
              _vm._m(0),
              _vm._l(
                _vm.laboral_references,
                function (laboral_reference, index) {
                  return _c("tr", { key: index, staticClass: "row-table" }, [
                    _c("td", { staticClass: "row-content" }, [
                      _vm._v(_vm._s(laboral_reference.company_name)),
                    ]),
                    _c("td", { staticClass: "row-content" }, [
                      _vm._v(_vm._s(laboral_reference.charge)),
                    ]),
                    _c("td", { staticClass: "row-content" }, [
                      _vm._v(_vm._s(laboral_reference.start_at)),
                    ]),
                    _c("td", { staticClass: "row-content" }, [
                      _vm._v(_vm._s(laboral_reference.leave_at)),
                    ]),
                    _c("td", { staticClass: "row-content" }, [
                      _vm._v(_vm._s(laboral_reference.contact_name)),
                    ]),
                    _c("td", { staticClass: "row-content" }, [
                      _vm._v(_vm._s(laboral_reference.contact_charge)),
                    ]),
                  ])
                }
              ),
            ],
            2
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticClass: "row-table" }, [
      _c("th", { staticClass: "row-content" }, [_vm._v("Empresa")]),
      _c("th", { staticClass: "row-content" }, [_vm._v("Cargo desempeñado")]),
      _c("th", { staticClass: "row-content" }, [_vm._v("Fecha inicial")]),
      _c("th", { staticClass: "row-content" }, [_vm._v("Fecha final")]),
      _c("th", { staticClass: "row-content" }, [_vm._v("Contacto")]),
      _c("th", { staticClass: "row-content" }, [_vm._v("Cargo del contacto")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }