var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "basic-information" },
    [
      _c(
        "vs-row",
        { staticClass: "title" },
        [
          _c(
            "vs-col",
            {
              attrs: {
                "vs-type": "flex",
                "vs-justify": "center",
                "vs-align": "center",
                "vs-w": "6",
              },
            },
            [
              _c("h3", [
                _vm._v("\n        " + _vm._s(_vm.titleForm) + "\n      "),
              ]),
            ]
          ),
        ],
        1
      ),
      _c("br"),
      _c(
        "vs-row",
        [
          _c(
            "vs-col",
            {
              attrs: {
                "vs-type": "flex",
                "vs-justify": "center",
                "vs-align": "center",
                "vs-w": "12",
              },
            },
            [
              _vm._v(
                "\n      Compártenos tres referencias familiares que nos puedan hablar sobre ti.\n    "
              ),
            ]
          ),
        ],
        1
      ),
      _c("br"),
      _c(
        "form",
        [
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-1/4 w-full" },
              [
                _c(
                  "label",
                  {
                    style: _vm.colorLabel(
                      _vm.errors.has("family_reference_full_name")
                    ),
                  },
                  [_vm._v("Nombre completo*")]
                ),
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  attrs: {
                    size: "large",
                    placeholder: "Nombre completo",
                    name: "family_reference_full_name",
                  },
                  model: {
                    value: _vm.form.family_reference_full_name,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "family_reference_full_name", $$v)
                    },
                    expression: "form.family_reference_full_name",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/4 w-full" },
              [
                _c(
                  "label",
                  {
                    style: _vm.colorLabel(
                      _vm.errors.has("family_reference_age")
                    ),
                  },
                  [_vm._v("Edad*")]
                ),
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  attrs: {
                    type: "number",
                    size: "large",
                    placeholder: "Edad",
                    name: "family_reference_age",
                  },
                  model: {
                    value: _vm.form.family_reference_age,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "family_reference_age", $$v)
                    },
                    expression: "form.family_reference_age",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/5 w-full" },
              [
                _c(
                  "label",
                  {
                    style: _vm.colorLabel(
                      _vm.errors.has("family_reference_relationship")
                    ),
                  },
                  [_vm._v("Parentesco*")]
                ),
                _c(
                  "vs-select",
                  {
                    staticClass: "w-full select-large mt-5 select-simple",
                    attrs: { name: "family_reference_relationship" },
                    model: {
                      value: _vm.form.family_reference_relationship,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "family_reference_relationship", $$v)
                      },
                      expression: "form.family_reference_relationship",
                    },
                  },
                  _vm._l(_vm.relations, function (item, index) {
                    return _c("vs-select-item", {
                      key: index,
                      attrs: { value: item.id, text: item.label },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/4 w-full" },
              [
                _c(
                  "label",
                  {
                    style: _vm.colorLabel(
                      _vm.errors.has("family_reference_telephone")
                    ),
                  },
                  [_vm._v("Teléfono*")]
                ),
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  attrs: {
                    size: "large",
                    placeholder: "Teléfono",
                    name: "family_reference_telephone",
                  },
                  model: {
                    value: _vm.form.family_reference_telephone,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "family_reference_telephone", $$v)
                    },
                    expression: "form.family_reference_telephone",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-1/4 w-full" },
              [
                _c(
                  "label",
                  {
                    style: _vm.colorLabel(
                      _vm.errors.has("family_reference_email")
                    ),
                  },
                  [_vm._v("Email*")]
                ),
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  attrs: {
                    size: "large",
                    placeholder: "Nombre completo",
                    name: "family_reference_email",
                  },
                  model: {
                    value: _vm.form.family_reference_email,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "family_reference_email", $$v)
                    },
                    expression: "form.family_reference_email",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/4 w-full" },
              [
                _c(
                  "label",
                  {
                    style: _vm.colorLabel(
                      _vm.errors.has("family_reference_city_id")
                    ),
                  },
                  [_vm._v("Ciudad de residencia*")]
                ),
                _c("v-select", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  attrs: {
                    options: _vm.cities(),
                    name: "family_reference_city_id",
                  },
                  model: {
                    value: _vm.form.family_reference_city_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "family_reference_city_id", $$v)
                    },
                    expression: "form.family_reference_city_id",
                  },
                }),
              ],
              1
            ),
          ]),
          _c(
            "vs-row",
            { staticClass: "row-btn-save" },
            [
              _c(
                "vs-col",
                {
                  attrs: {
                    "vs-offset": "10",
                    "vs-type": "flex",
                    "vs-justify": "center",
                    "vs-align": "center",
                    "vs-w": "2",
                  },
                },
                [
                  _c(
                    "vs-button",
                    {
                      staticClass: "mt-5 block",
                      attrs: { type: "filled" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.submitForm.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("Guardar")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "vx-row" }, [
        _c("div", { staticClass: "vx-col w-full lg:w-1/1 mb-base" }, [
          _c(
            "table",
            { staticClass: "table-references" },
            [
              _vm._m(0),
              _vm._l(_vm.family_references, function (family_reference, index) {
                return _c("tr", { key: index, staticClass: "row-table" }, [
                  _c("td", { staticClass: "row-content" }, [
                    _vm._v(_vm._s(family_reference.family_reference_full_name)),
                  ]),
                  _c("td", { staticClass: "row-content" }, [
                    _vm._v(_vm._s(family_reference.family_reference_age)),
                  ]),
                  _c("td", { staticClass: "row-content" }, [
                    _vm._v(
                      _vm._s(family_reference.family_reference_relationship)
                    ),
                  ]),
                  _c("td", { staticClass: "row-content" }, [
                    _vm._v(_vm._s(family_reference.family_reference_telephone)),
                  ]),
                  _c("td", { staticClass: "row-content" }, [
                    _vm._v(_vm._s(family_reference.family_reference_email)),
                  ]),
                ])
              }),
            ],
            2
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticClass: "row-table" }, [
      _c("th", { staticClass: "row-content" }, [_vm._v("Nombre")]),
      _c("th", { staticClass: "row-content" }, [_vm._v("Edad")]),
      _c("th", { staticClass: "row-content" }, [_vm._v("Parentesco")]),
      _c("th", { staticClass: "row-content" }, [_vm._v("Teléfono")]),
      _c("th", { staticClass: "row-content" }, [_vm._v("Email")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }