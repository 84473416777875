<template>
  <div class="">
    <vs-popup class="" title="Mensaje de Globalwork" :active.sync="modalMessageSaveInformation">
      <p>
        {{messageSaveInformation}}
      </p>
    </vs-popup>
    <vs-popup fullscreen v-bind:title="titleLegal" :active.sync="popupLegal">
      <iframe v-bind:src="document" frameborder="0" width="100%" height="1000" marginheight="0" marginwidth="0" id="pdf"></iframe>
    </vs-popup>
    <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12" class="name-candidate">
      <form-wizard color="rgba(var(--vs-primary), 1)" errorColor="rgba(var(--vs-danger), 1)" :title="candidateName" subtitle="Por favor llena todos los campos" finishButtonText="Guardar información" backButtonText="anterior" nextButtonText="Siguiente">
        <tab-content title="Términos y condiciones" class="mb-5" icon="feather icon-file-text" :before-change="validatePolicies">
          <!-- <BasicInformation v-bind:candidate="candidate"/> -->

            <vs-row>
              <vs-col vs-offset="4"  vs-type="flex" vs-justify="center" vs-align="center" vs-w="4">
                <vs-checkbox v-model="candidate.terms">
                </vs-checkbox>
                <vs-button color="primary" type="flat" @click="openLegal(1)">Términos y condiciones</vs-button>
              </vs-col>
            </vs-row>
            <br>
            <vs-row>
              <vs-col vs-offset="4"  vs-type="flex" vs-justify="center" vs-align="center" vs-w="4">
                <vs-checkbox v-model="candidate.privacy">
                </vs-checkbox>
                <vs-button color="primary" type="flat" @click="openLegal(2)">Política de privacidad</vs-button>
              </vs-col>
            </vs-row>

        </tab-content>

        <tab-content title="Información básica" class="mb-5" icon="feather icon-home" :before-change="validateInformationBasic">
          <!-- <BasicInformation v-bind:candidate="candidate"/> -->
          <vs-row class="title">
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
              <h3>
                Información básica
              </h3>
            </vs-col>
          </vs-row>
          <br>
          <vs-row>
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
              Cuéntanos sobre ti.
            </vs-col>
          </vs-row>
          <br>
          <form>
            <div class="vx-row">
              <div class="vx-col md:w-1/3 w-full">
                <label v-bind:style="colorLabel(errors.has('first_name'))">Nombre Aspirante*</label>
                <vs-input size="large" v-validate="'required'" placeholder="Nombre aspirante" name="first_name" v-model="candidate.first_name" />
              </div>
              <div class="vx-col md:w-1/3 w-full">
                <label v-bind:style="colorLabel(errors.has('last_name'))">Apellidos aspirante*</label>
                <vs-input size="large" v-validate="'required'" placeholder="Apellidos aspirante" name="last_name" v-model="candidate.last_name" />
              </div>
              <div class="vx-col md:w-1/3 w-full">
                <label v-bind:style="colorLabel(errors.has('birth_date'))">Fecha de nacimiento*</label>
                <datepicker placeholder="Fecha de nacimiento" v-validate="'required'" name="birth_date" v-model="candidate.birth_date"></datepicker>
              </div>
            </div>

            <div class="vx-row">
              <div class="vx-col md:w-1/3 w-full">
                <label v-bind:style="colorLabel(errors.has('gender'))">Género*</label>
                <vs-select v-model="candidate.gender" class="w-full select-large mt-5 select-simple" name="gender" v-validate="'required'">
                  <vs-select-item :key="index" :value="item.id" :text="item.label" v-for="(item,index) in genders" />
                </vs-select>
              </div>
              <div class="vx-col md:w-1/3 w-full">
                <label v-bind:style="colorLabel(errors.has('civil_status'))">Estado civil*</label>
                <vs-select v-model="candidate.civil_status" class="w-full select-large mt-5 select-simple" name="civil_status" v-validate="'required'">
                  <vs-select-item :key="index" :value="item.id" :text="item.label" v-for="(item,index) in civilTypes" />
                </vs-select>
              </div>
              <!-- <div class="vx-col md:w-1/5 w-full">
                <label for="">Libreta militar #</label>
                <vs-input size="large" placeholder="Libreta militar #" name="military_card_number" v-model="candidate.military_card_number" />
              </div>
              <div class="vx-col md:w-1/5 w-full">
                <label for="">Clase Libreta militar</label>
                <vs-input size="large" placeholder="Clase Libreta militar" name="military_card_type" v-model="candidate.military_card_type" />
              </div> -->
              <div class="vx-col md:w-1/3 w-full">
                <label v-bind:style="colorLabel(errors.has('blood_type'))">Grupo sanguíneo*</label>
                <vs-select v-model="candidate.blood_type" class="w-full select-large mt-5 select-simple" name="bloodTypes" v-validate="'required'">
                  <vs-select-item :key="index" :value="item.id" :text="item.label" v-for="(item,index) in bloodTypes" />
                </vs-select>
              </div>
            </div>

            <div class="vx-row">
              <div class="vx-col md:w-1/4 w-full">
                <label v-bind:style="colorLabel(errors.has('current_activity'))">Actividad actual*</label>
                <vs-input size="large" v-validate="'required'" placeholder="Actividad actual" name="current_activity" v-model="candidate.current_activity" />
              </div>
              <!-- <div class="vx-col md:w-1/5 w-full">
                <label for="">Placa</label>
                <vs-input size="large" placeholder="Placa" name="licence_plate" v-model="candidate.licence_plate" />
              </div> -->
              <div class="vx-col md:w-1/4 w-full">
                <label v-bind:style="colorLabel(errors.has('city_id'))">Ciudad de residencia*</label>
                <v-select v-model="candidate.city_id" :options="cities()" v-validate="'required'" name="city_id" ></v-select>
              </div>
              <div class="vx-col md:w-1/4 w-full">
                <label v-bind:style="colorLabel(errors.has('location'))">Localidad / Barrio*</label>
                <vs-input size="large" v-validate="'required'" placeholder="Localidad" name="location" v-model="candidate.location" />
              </div>
              <div class="vx-col md:w-1/4 w-full">
                <label v-bind:style="colorLabel(errors.has('address'))">Dirección*</label>
                <vs-input size="large" v-validate="'required'" placeholder="Dirección" name="address" v-model="candidate.address" />
              </div>
            </div>

            <div class="vx-row">
              <div class="vx-col md:w-1/4 w-full">
                <label v-bind:style="colorLabel(errors.has('document_type'))">Tipo de documento*</label>
                <vs-select v-model="candidate.document_type" class="w-full select-large mt-5 select-simple" name="document_type" v-validate="'required'">
                  <vs-select-item :key="index" :value="item.id" :text="item.label" v-for="(item,index) in idTypes" />
                </vs-select>
              </div>
              <div class="vx-col md:w-1/4 w-full">
                <label v-bind:style="colorLabel(errors.has('person_id'))">Cédula de ciudadanía*</label>
                <vs-input size="large" v-validate="'required'" placeholder="Cédula de ciudadanía" name="person_id" v-model="candidate.person_id" />
              </div>
              <div class="vx-col md:w-1/4 w-full">
                <label v-bind:style="colorLabel(errors.has('email'))">Email*</label>
                <vs-input size="large" v-validate="'required'" placeholder="Email" name="email" v-model="candidate.email" />
                <span class="text-danger message-field-require"  v-show="errors.has('candidate.email')">*</span>
              </div>
              <div class="vx-col md:w-1/4 w-full">
                <label v-bind:style="colorLabel(errors.has('phone'))">Teléfono*</label>
                <vs-input size="large" v-validate="'required'" placeholder="Teléfono" name="phone" v-model="candidate.phone" />
                <span class="text-danger message-field-require"  v-show="errors.has('candidate.phone')">*</span>
              </div>
            </div>

            <!-- button sumit -->
            <!-- <vs-row class="row-btn-save">
              <vs-col vs-offset="10" vs-type="flex" vs-justify="center" vs-align="center" vs-w="2">
                <vs-button type="filled" @click.prevent="submitFormBasicInformation" class="mt-5 block">Agregar</vs-button>
              </vs-col>
            </vs-row>       -->
          </form>
        </tab-content>

        <!-- tab 2 content -->
        <tab-content title="Referencias familiares" class="mb-5" icon="feather icon-users" :before-change="validatesFamilyReferences">
          <!-- <FamilyReferences v-bind:candidate="candidate"/> -->
          <vs-row class="title">
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
              <h3>
                Referencias familiares
              </h3>
            </vs-col>
          </vs-row>
          <br>
          <vs-row>
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
              Compártenos tres referencias familiares que nos puedan hablar sobre ti.
            </vs-col>
          </vs-row>
          <br>
          <form>
            <div class="vx-row">
              <div class="vx-col md:w-1/4 w-full">
                <label v-bind:style="colorLabel(errors.has('family_reference_full_name'))">Nombre completo*</label>
                <vs-input size="large" v-validate="'required'" placeholder="Nombre completo" name="family_reference_full_name" v-model="formFamilyReferences.family_reference_full_name" />
              </div>
              <div class="vx-col md:w-1/4 w-full">
                <label v-bind:style="colorLabel(errors.has('family_reference_age'))">Edad*</label>
                <vs-input type="number" size="large" v-validate="'required'" placeholder="Edad" name="family_reference_age" v-model="formFamilyReferences.family_reference_age" />
              </div>
              <div class="vx-col md:w-1/5 w-full">
                <label v-bind:style="colorLabel(errors.has('family_reference_relationship'))">Parentesco*</label>
                <vs-select v-model="formFamilyReferences.family_reference_relationship" class="w-full select-large mt-5 select-simple" name="family_reference_relationship">
                  <vs-select-item :key="index" :value="item.id" :text="item.label" v-for="(item,index) in relations" />
                </vs-select>
              </div>
              <div class="vx-col md:w-1/4 w-full">
                <label v-bind:style="colorLabel(errors.has('family_reference_telephone'))">Teléfono*</label>
                <vs-input size="large" v-validate="'required'" placeholder="Teléfono" name="family_reference_telephone" v-model="formFamilyReferences.family_reference_telephone" />
              </div>
            </div>

            <div class="vx-row">
              <div class="vx-col md:w-1/4 w-full">
                <label v-bind:style="colorLabel(errors.has('family_reference_email'))">Email*</label>
                <vs-input size="large" v-validate="'required'" placeholder="Nombre completo" name="family_reference_email" v-model="formFamilyReferences.family_reference_email" />
              </div>
              <div class="vx-col md:w-1/4 w-full">
                <label v-bind:style="colorLabel(errors.has('family_reference_city_id'))">Ciudad de residencia*</label>
                <v-select v-model="formFamilyReferences.family_reference_city_id" :options="cities()" v-validate="'required'" name="family_reference_city_id" ></v-select>
              </div>
            </div>

            <!-- button sumit -->
            <vs-row class="row-btn-save">
              <vs-col vs-offset="10"  vs-type="flex" vs-justify="center" vs-align="center" vs-w="2">
                <vs-button type="filled" @click.prevent="submitFormFamilyReferences" class="mt-5 block">Agregar</vs-button>
              </vs-col>
            </vs-row>
          </form>
          <div class="vx-row">
            <div class="vx-col w-full lg:w-1/1 mb-base">
              <table class="table-references">
                <tr class="row-table">
                  <th class="row-content">Nombre</th>
                  <th class="row-content">Edad</th>
                  <th class="row-content">Parentesco</th>
                  <th class="row-content">Teléfono</th>
                  <th class="row-content">Email</th>
                  <!-- <th class="row-content">Ciudad</th>             -->
                </tr>
                <tr class="row-table" v-for="(family_reference, index) in family_references" :key="index">
                  <td class="row-content">{{family_reference.family_reference_full_name}}</td>
                  <td class="row-content">{{family_reference.family_reference_age}}</td>
                  <td class="row-content">{{family_reference.family_reference_relationship}}</td>
                  <td class="row-content">{{family_reference.family_reference_telephone}}</td>
                  <td class="row-content">{{family_reference.family_reference_email}}</td>
                  <!-- <td class="row-content">{{family_reference.family_reference_city_id}}</td> -->
                </tr>
              </table>
            </div>
          </div>
        </tab-content>

        <!-- tab 3 content -->
        <tab-content title="Referencias personales" class="mb-5" icon="feather icon-user-check" :before-change="validatesPersonalReferences">
          <!-- <PersonalReferences v-bind:candidate="candidate"/> -->
          <vs-row class="title">
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
              <h3>
                Referencias personales
              </h3>
            </vs-col>
          </vs-row>
          <br>
          <vs-row>
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
              Dinos a qué amigos o compañeros podemos contactar.
            </vs-col>
          </vs-row>
          <br>
          <form>

            <div class="vx-row">
              <div class="vx-col md:w-1/4 w-full">
                <label v-bind:style="colorLabel(errors.has('personal_reference_name'))">Nombre completo*</label>
                <vs-input size="large" v-validate="'required'" placeholder="Nombre completo" name="personal_reference_name" v-model="formPersonalReferences.personal_reference_name" />
              </div>
              <div class="vx-col md:w-1/4 w-full">
                <label v-bind:style="colorLabel(errors.has('personal_reference_age'))">Edad*</label>
                <vs-input type="number" size="large" v-validate="'required'" placeholder="Edad" name="personal_reference_age" v-model="formPersonalReferences.personal_reference_age" />
              </div>
              <div class="vx-col md:w-1/5 w-full">
                <label v-bind:style="colorLabel(errors.has('personal_reference_relation_type'))">Tipo de relación*</label>
                <vs-select v-model="formPersonalReferences.personal_reference_relation_type" class="w-full select-large mt-5 select-simple" name="personal_reference_relation_type" v-validate="'required'">
                  <vs-select-item :key="index" :value="item.id" :text="item.label" v-for="(item,index) in relations" />
                </vs-select>
              </div>
              <div class="vx-col md:w-1/4 w-full">
                <label v-bind:style="colorLabel(errors.has('personal_reference_telephone'))">Teléfono*</label>
                <vs-input size="large" v-validate="'required'" placeholder="Teléfono" name="personal_reference_telephone" v-model="formPersonalReferences.personal_reference_telephone" />
              </div>
            </div>

            <div class="vx-row">
              <div class="vx-col md:w-1/4 w-full">
                <label v-bind:style="colorLabel(errors.has('personal_reference_email'))">Email*</label>
                <vs-input size="large" v-validate="'required'" placeholder="Nombre completo" name="personal_reference_email" v-model="formPersonalReferences.personal_reference_email" />
              </div>
              <div class="vx-col md:w-1/4 w-full">
                <label v-bind:style="colorLabel(errors.has('personal_reference_city_id'))">Ciudad de residencia*</label>
                <v-select v-model="formPersonalReferences.personal_reference_city_id" :options="cities()" v-validate="'required'" name="personal_reference_city_id" ></v-select>
              </div>
            </div>

            <!-- button sumit -->
            <vs-row class="row-btn-save">
              <vs-col vs-offset="10"  vs-type="flex" vs-justify="center" vs-align="center" vs-w="2">
                <vs-button type="filled" @click.prevent="submitFormPersonalReferences" class="mt-5 block">Agregar</vs-button>
              </vs-col>
            </vs-row>
          </form>
          <div class="vx-row">
            <div class="vx-col w-full lg:w-1/1 mb-base">
              <table class="table-references">
                <tr class="row-table">
                  <th class="row-content">Nombre</th>
                  <th class="row-content">Edad</th>
                  <th class="row-content">Parentesco</th>
                  <th class="row-content">Teléfono</th>
                  <th class="row-content">Email</th>
                  <!-- <th class="row-content">Ciudad</th>             -->
                </tr>
                <tr class="row-table" v-for="(personal_reference, index) in personal_references" :key="index">
                  <td class="row-content">{{personal_reference.personal_reference_name}}</td>
                  <td class="row-content">{{personal_reference.personal_reference_age}}</td>
                  <td class="row-content">{{personal_reference.personal_reference_relation_type}}</td>
                  <td class="row-content">{{personal_reference.personal_reference_telephone}}</td>
                  <td class="row-content">{{personal_reference.personal_reference_email}}</td>
                  <!-- <td class="row-content">{{personal_reference.personal_reference_city_id}}</td> -->
                </tr>
              </table>
            </div>
          </div>
        </tab-content>

        <!-- tab 4 content -->
        <tab-content title="Estudios básicos" class="mb-5" icon="feather icon-book-open" :before-change="validatesAcademicReferencesBasic">
          <!-- <AcademicReferencesBasic v-bind:candidate="candidate"/> -->
          <vs-row class="title">
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
              <h3>
                Estudios básicos
              </h3>
            </vs-col>
          </vs-row>
          <br>
          <vs-row>
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
              Ingresa las instituciones educativas en las que estudiaste la primaria y el bachillerato.
            </vs-col>
          </vs-row>
          <br>
          <form>

            <div class="vx-row">
              <div class="vx-col md:w-1/4 w-full">
                <label v-bind:style="colorLabel(errors.has('name_institution_basic'))">Nombre institución*</label>
                <vs-input size="large" v-validate="'required'" placeholder="Nombre institución" name="name_institution_basic" v-model="formAcademicReferencesBasic.name_institution" />
              </div>
              <div class="vx-col md:w-1/4 w-full">
                <label v-bind:style="colorLabel(errors.has('name_title_basic'))">Título obtenido*</label>
                <vs-input size="large" v-validate="'required'" placeholder="Título obtenido" name="name_title_basic" v-model="formAcademicReferencesBasic.name_title" />
              </div>
              <div class="vx-col md:w-1/4 w-full">
                <label v-bind:style="colorLabel(errors.has('date_graduation_basic'))">Fecha de graduación*</label>
                <datepicker placeholder="Fecha de graduación" name="date_graduation_basic" v-model="formAcademicReferencesBasic.date_graduation"></datepicker>
              </div>
              <div class="vx-col md:w-1/4 w-full">
                <label v-bind:style="colorLabel(errors.has('city_id_basic'))">Ciudad*</label>
                <v-select v-model="formAcademicReferencesBasic.city_id" :options="cities()" v-validate="'required'" name="city_id_basic" ></v-select>
              </div>
            </div>

            <!-- button sumit -->
            <vs-row class="row-btn-save">
              <vs-col vs-offset="10" vs-type="flex" vs-justify="center" vs-align="center" vs-w="2">
                <vs-button type="filled" @click.prevent="submitFormAcademicReferencesBasic" class="mt-5 block">Agregar</vs-button>
              </vs-col>
            </vs-row>
          </form>
          <div class="vx-row">
            <div class="vx-col w-full lg:w-1/1 mb-base">
              <table class="table-references">
                <tr class="row-table">
                  <th class="row-content">Nombre institución</th>
                  <th class="row-content">Título</th>
                  <th class="row-content">Fecha de graduación</th>
                  <!-- <th class="row-content">Ciudad</th> -->
                </tr>
                <tr class="row-table" v-for="(academic_reference, index) in academic_references_basic" :key="index">
                  <td class="row-content">{{academic_reference.name_institution}}</td>
                  <td class="row-content">{{academic_reference.name_title}}</td>
                  <td class="row-content">{{academic_reference.date_graduation}}</td>
                  <!-- <td class="row-content">{{academic_reference.city_id}}</td> -->
                </tr>
              </table>
            </div>
          </div>
        </tab-content>

        <!-- tab 5 content -->
        <tab-content title="Estudios superiores" class="mb-5" icon="feather icon-book" :before-change="validatesAcademicReferencesSuperior">
          <!-- <AcademicReferencesSuperior v-bind:candidate="candidate"/> -->
          <vs-row class="title">
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
              <h3>
                Estudios superiores
              </h3>
            </vs-col>
          </vs-row>
          <br>
          <vs-row>
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
              Compártenos tus dos últimos estudios terminados: técnica, tecnología, pregrado, posgrado (no aplican cursos cortos).
            </vs-col>
          </vs-row>
          <br>
          <form>

            <div class="vx-row">
              <div class="vx-col md:w-1/4 w-full">
                <label v-bind:style="colorLabel(errors.has('name_institution'))">Nombre institución*</label>
                <vs-input size="large" v-validate="'required'" placeholder="Nombre institución" name="name_institution" v-model="formAcademicReferencesSuperior.name_institution" />
              </div>
              <div class="vx-col md:w-1/4 w-full">
                <label v-bind:style="colorLabel(errors.has('name_title'))">Título obtenido*</label>
                <vs-input size="large" v-validate="'required'" placeholder="Título obtenido" name="name_title" v-model="formAcademicReferencesSuperior.name_title" />
              </div>
              <div class="vx-col md:w-1/4 w-full">
                <label v-bind:style="colorLabel(errors.has('date_graduation'))">Fecha de graduación*</label>
                <datepicker placeholder="Fecha de graduación" name="date_graduation" v-model="formAcademicReferencesSuperior.date_graduation"></datepicker>
              </div>
              <div class="vx-col md:w-1/4 w-full">
                <label v-bind:style="colorLabel(errors.has('city_id'))">Ciudad*</label>
                <v-select v-model="formAcademicReferencesSuperior.city_id" :options="cities()" v-validate="'required'" name="city_id" ></v-select>
              </div>
            </div>

            <!-- button sumit -->
            <vs-row class="row-btn-save">
              <vs-col vs-offset="10" vs-type="flex" vs-justify="center" vs-align="center" vs-w="2">
                <vs-button type="filled" @click.prevent="submitFormAcademicReferencesSuperior" class="mt-5 block">Agregar</vs-button>
              </vs-col>
            </vs-row>
          </form>
          <div class="vx-row">
            <div class="vx-col w-full lg:w-1/1 mb-base">
              <table class="table-references">
                <tr class="row-table">
                  <th class="row-content">Nombre institución</th>
                  <th class="row-content">Título</th>
                  <th class="row-content">Fecha de graduación</th>
                  <!-- <th class="row-content">Ciudad</th> -->
                </tr>
                <tr class="row-table" v-for="(academic_reference, index) in academic_references_superior" :key="index">
                  <td class="row-content">{{academic_reference.name_institution}}</td>
                  <td class="row-content">{{academic_reference.name_title}}</td>
                  <td class="row-content">{{academic_reference.date_graduation}}</td>
                  <!-- <td class="row-content">{{academic_reference.city_id}}</td> -->
                </tr>
              </table>
            </div>
          </div>
        </tab-content>

        <!-- tab 6 content -->
        <tab-content title="Referencias laborales" class="mb-5" icon="feather icon-briefcase" :before-change="validatesLaboralReferences">
          <!-- <LaboralReferences v-bind:candidate="candidate"/> -->
          <vs-row class="title">
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
              <h3>
                Referencias laborales oka
              </h3>
            </vs-col>
          </vs-row>
          <br>
          <vs-row>
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
              Ingresa los datos de tus últimas dos experiencias laborales.
            </vs-col>
          </vs-row>
          <br>
          <form>

            <div class="vx-row">
              <div class="vx-col md:w-1/4 w-full">
                <label v-bind:style="colorLabel(errors.has('company_name'))">Empresa*</label>
                <vs-input size="large" v-validate="'required'" placeholder="Empresa" name="company_name" v-model="formLaboralReferences.company_name" />
              </div>
              <div class="vx-col md:w-1/4 w-full">
                <label v-bind:style="colorLabel(errors.has('charge'))">Cargo desempeñado*</label>
                <vs-input size="large" v-validate="'required'" placeholder="Cargo desempeñado" name="charge" v-model="formLaboralReferences.charge" />
              </div>
              <div class="vx-col md:w-1/4 w-full">
                <label v-bind:style="colorLabel(errors.has('start_at'))">Fecha inicial*</label>
                <datepicker placeholder="Fecha inicial" name="start_at" v-model="formLaboralReferences.start_at"></datepicker>
              </div>
              <div class="vx-col md:w-1/4 w-full">
                <label v-bind:style="colorLabel(errors.has('leave_at'))">Fecha final*</label>
                <datepicker placeholder="Fecha final" name="leave_at" v-model="formLaboralReferences.leave_at"></datepicker>
              </div>
            </div>

            <div class="vx-row">
              <div class="vx-col md:w-1/4 w-full">
                <label v-bind:style="colorLabel(errors.has('contact_name'))">Nombre contacto*</label>
                <vs-input size="large" v-validate="'required'" placeholder="Nombre contacto" name="contact_name" v-model="formLaboralReferences.contact_name" />
              </div>
              <div class="vx-col md:w-1/4 w-full">
                <label v-bind:style="colorLabel(errors.has('contact_charge'))">Cargo del contacto contacto*</label>
                <vs-input size="large" v-validate="'required'" placeholder="Cargo del contacto" name="contact_charge" v-model="formLaboralReferences.contact_charge" />
              </div>
              <div class="vx-col md:w-1/4 w-full">
                <label v-bind:style="colorLabel(errors.has('contact_telephone'))">Teléfono del contacto*</label>
                <vs-input size="large" v-validate="'required'" placeholder="Teléfono del contacto" name="contact_telephone" v-model="formLaboralReferences.contact_telephone" />
              </div>
              <div class="vx-col md:w-1/4 w-full">
                <label v-bind:style="colorLabel(errors.has('salary'))">Email*</label>
                <vs-input size="large" v-validate="'required'" placeholder="Email" name="salary" v-model="formLaboralReferences.email" />
              </div>
            </div>

            <div class="vx-row">
              <div class="vx-col md:w-1/4 w-full">
                <label v-bind:style="colorLabel(errors.has('salary'))">Última asignación salarial*</label>
                <vs-input size="large" v-validate="'required'" placeholder="Última asignación salarial" name="salary" v-model="formLaboralReferences.salary" />
              </div>
              <div class="vx-col md:w-1/4 w-full">
                <label v-bind:style="colorLabel(errors.has('contract'))">Tipo de contrato*</label>
                <vs-select v-model="formLaboralReferences.contract" class="w-full select-large mt-5 select-simple" name="contract" v-validate="'required'">
                  <vs-select-item :key="index" :value="item.id" :text="item.label" v-for="(item,index) in typeContracts" />
                </vs-select>
              </div>
              <!-- <div class="vx-col md:w-1/4 w-full">
                <label v-bind:style="colorLabel(errors.has('calls'))">Llamados de atención*</label>
                <vs-input type="number" size="large" v-validate="'required'" placeholder="Llamados de atención" name="calls" v-model="formLaboralReferences.calls" />
              </div> -->
              <div class="vx-col md:w-1/4 w-full">
                <label v-bind:style="colorLabel(errors.has('retirement'))">Motivo de retiro*</label>
                <vs-input size="large" v-validate="'required'" placeholder="Motivo de retiro" name="retirement" v-model="formLaboralReferences.retirement" />
              </div>
            </div>
            <!-- button sumit -->
            <vs-row class="row-btn-save">
              <vs-col vs-offset="10"  vs-type="flex" vs-justify="center" vs-align="center" vs-w="2">
                <vs-button type="filled" @click.prevent="submitFormLaboralReferences" class="mt-5 block">Agregar</vs-button>
              </vs-col>
            </vs-row>
          </form>
          <div class="vx-row">
            <div class="vx-col w-full lg:w-1/1 mb-base">
              <table class="table-references">
                <tr class="row-table">
                  <th class="row-content">Empresa</th>
                  <th class="row-content">Cargo desempeñado</th>
                  <th class="row-content">Fecha inicial</th>
                  <th class="row-content">Fecha final</th>
                  <th class="row-content">Contacto</th>
                  <th class="row-content">Cargo del contacto</th>
                </tr>
                <tr class="row-table" v-for="(laboral_reference, index) in laboral_references" :key="index">
                  <td class="row-content">{{laboral_reference.company_name}}</td>
                  <td class="row-content">{{laboral_reference.charge}}</td>
                  <td class="row-content">{{laboral_reference.start_at}}</td>
                  <td class="row-content">{{laboral_reference.leave_at}}</td>
                  <td class="row-content">{{laboral_reference.contact_name}}</td>
                  <td class="row-content">{{laboral_reference.contact_charge}}</td>
                </tr>
              </table>
            </div>
          </div>
        </tab-content>
      </form-wizard>
    </vs-col>
  </div>
</template>

<script>
import BasicInformation from './basic-information/BasicInformation'
import AcademicReferencesBasic from './academic-references/AcademicReferencesBasic'
import AcademicReferencesSuperior from './academic-references/AcademicReferencesSuperior'
import FamilyReferences from './family-references/FamilyReferences'
import LaboralReferences from './laboral-references/LaboralReferences'
import PersonalReferences from './personal-references/PersonalReferences'
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import api from '../../mixins/api.js'
import cities from '../../mixins/cities.js'
import vSelect from 'vue-select';
import Datepicker from 'vuejs-datepicker';
export default {
  mixins: [api, cities],
  data(){
    return{
      candidateName: 'Nombre del candidato',
      titleLegal: '',
      popupLegal: false,
      document: '',
      modalMessageSaveInformation: false,
      messageSaveInformation: '',
      // positionName: 'Cargo',
      // positionDescription: 'Descripción del Cargo',
      typeContracts: [
        {id: 0, label: 'Indefinido'},
        {id: 1, label: 'Fijo'},
        {id: 2, label: 'Obra labor'},
        {id: 3, label: 'Prestación de servicios'},
        {id: 5, label: 'Freelancer'},
        {id: 6, label: 'Aprendiz'},
        {id: 7, label: 'Practicante'},
        {id: 4, label: 'Otro'},
      ],
      relations: [
        {id: null, label: 'Seleccione una opción'},
        {id: 7, label: 'Amigo/a'},
        {id: 0, label: 'Hermano/a'},
        {id: 1, label: 'Hijo/a'},
        {id: 2, label: 'Padre/Madre'},
        {id: 3, label: 'Tio/a'},
        {id: 4, label: 'Abuelo/a'},
        {id: 5, label: 'Primo/a'},
        {id: 6, label: 'Sobrino/a'},
        {id: 8, label: 'Esposo/a'},
        {id: 9, label: 'Suegra'},
        {id: 10, label: 'Cuñado'},
        {id: 11, label: 'Otro'},
      ],
      idTypes: [
        {id: null, label: 'Seleccione una opción'},
        {id: 1, label: 'Cédula'},
        {id: 2, label: 'Tarjeta de identidad'},
        {id: 3, label: 'Pasaporte'},
        {id: 4, label: 'Cédula de extranjería'},
      ],
      genders: [
        {id: null, label: 'Seleccione una opción'},
        {id: 0, label: 'Femenino'},
        {id: 1, label: 'Masculino'}
      ],
      civilTypes: [
        {id: null, label: 'Seleccione una opción'},
        {id: 0, label: 'Soltero/a'},
        {id: 1, label: 'Casado/a'},
        {id: 3, label: 'Viudo/a'},
        {id: 4, label: 'Unión libre'}
      ],
      bloodTypes: [
        {id: null, label: 'Seleccione una opción'},
        {id: 0, label: 'O-'},
        {id: 0, label: 'O+'},
        {id: 0, label: 'A-'},
        {id: 0, label: 'A+'},
        {id: 0, label: 'B-'},
        {id: 0, label: 'B+'},
        {id: 0, label: 'AB-'},
        {id: 0, label: 'AB+'}
      ],
      family_references: [],
      personal_references: [],
      academic_references_basic: [],
      academic_references_superior: [],
      laboral_references: [],
      family_references_add: [],
      personal_references_add: [],
      academic_references_basic_add: [],
      academic_references_superior_add: [],
      laboral_references_add: [],
      candidate:{

      },
      formFamilyReferences: {
        family_reference_full_name: '',
        family_reference_age: '',
        family_reference_relationship: '',
        family_reference_telephone: '',
        family_reference_email: '',
        candidate_id: ''
      },
      formPersonalReferences: {
        personal_reference_name: '',
        personal_reference_age: '',
        personal_reference_relation_type: '',
        personal_reference_telephone: '',
        personal_reference_email: '',
        personal_reference_city_id: '',
        candidate_id: ''
      },
      formAcademicReferencesBasic: {
        name_institution: '',
        name_title: '',
        date_graduation: '',
        city_id: '',
        observations: '',
        project_id: '',
        candidate_id: '',
        academic_type: 0
      },
      formAcademicReferencesSuperior: {
        name_institution: '',
        name_title: '',
        date_graduation: '',
        city_id: '',
        observations: '',
        project_id: '',
        candidate_id: '',
        academic_type: 1
      },
      formLaboralReferences: {
        company_name: '', // add
        charge: '', // add
        start_at: '', // add
        leave_at: '', // add
        contact_name: '', // add
        contact_charge: '', // add
        contact_telephone: '', // add
        salary: '', // add
        contract: '', // add
        calls: '', // add
        retirement: '', // add
        email: '',
        candidate_id: ''
      }
    }
  },
  methods: {
    colorLabel(errorLabel){
      var cadenaColor = 'color: black';
      if(errorLabel){
        cadenaColor = 'color: red';
      }
      return cadenaColor;
    },
    validatePolicies(){
      return new Promise((resolve, reject) => {
        this.$validator.validateAll("step-0").then(result => {
          if (result) {
            if(this.candidate.terms && this.candidate.privacy){
              resolve(true)
            }else{
              reject("correct all values");
              this.$vs.notify({color:'danger',title:'Mensaje de Globalwork',text:'Debe aceptar los Términos y condiciones y la Política de privacidad', position: 'bottom-left'})
            }
          } else {
            reject("correct all values");
            this.$vs.notify({color:'danger',title:'Mensaje de Globalwork',text:'Debe aceptar los Términos y condiciones y la Política de privacidad', position: 'bottom-left'})
          }
        })
      })
    },
    validateInformationBasic() {
      return new Promise((resolve, reject) => {
        this.$validator.validateAll("step-1").then(result => {
          if (result) {
            resolve(true)
          } else {
            reject("correct all values");
          }
        })
      })
    },
    validatesFamilyReferences() {
      return new Promise((resolve, reject) => {
        this.$validator.validateAll("step-2").then(result => {
          if (result) {
            resolve(true)
          } else {
            reject("correct all values");
          }
        })
      })
    },
    validatesPersonalReferences() {
      return new Promise((resolve, reject) => {
        this.$validator.validateAll("step-3").then(result => {
          if (result) {
            resolve(true)
          } else {
            reject("correct all values");
          }
        })
      })
    },
    validatesAcademicReferencesBasic(){
      return new Promise((resolve, reject) => {
        this.$validator.validateAll("step-3").then(result => {
          if (result) {
            resolve(true)
          } else {
            reject("correct all values");
          }
        })
      })
    },
    validatesAcademicReferencesSuperior(){
      return new Promise((resolve, reject) => {
        this.$validator.validateAll("step-3").then(result => {
          if (result) {
            resolve(true)
          } else {
            reject("correct all values");
          }
        })
      })
    },
    validatesLaboralReferences(){
      console.log("Enviar form")
      var candidate_id = this.candidate.id
      var path_candidate = "/api/v1/candidates/"+candidate_id
      var path_family_references = "api/v1/family_references"
      var path_personal_references = "api/v1/personal_references"
      var path_academic_references = "api/v1/academic_references"
      var path_laboral_references = "api/v1/laboral_references"

      this.requestPut(this.url()+path_candidate, this.candidate, {}).then(response => {
        console.log(response)
        this.$vs.notify({color:'success',title:'Informacion  básica guardada',text:'Se ha guardado la información', position: 'bottom-left'})
        this.modalMessageSaveInformation = true
        this.messageSaveInformation = "La información se ha guardado. ¡Muchas gracias por diligenciar esta información!"
      }, (err) => {
        console.log('err users -> ' +  JSON.stringify(err))
        this.$vs.notify({color:'danger',title:'Ups',text:'No se guardo la información básica', position: 'bottom-left'})
        this.modalMessageSaveInformation = true
        this.messageSaveInformation = "No se guardo la información"
      })

      this.family_references_add.forEach(family_reference => {
        this.sendReferences(path_family_references, family_reference, "Referencia familiar creada")
      });

      this.personal_references_add.forEach(personal_reference => {
        this.sendReferences(path_personal_references, personal_reference, "Referencia personal creada")
      });

      this.academic_references_basic_add.forEach(academic_references_basic => {
        this.sendReferences(path_academic_references, academic_references_basic, "Referencia Académica básica creada")
      });

      this.academic_references_superior_add.forEach(academic_references_superior => {
        this.sendReferences(path_academic_references, academic_references_superior, "Referencia Académica superior creada")
      });

      this.laboral_references_add.forEach(laboral_reference => {
        this.sendReferences(path_laboral_references, laboral_reference, "Referencia laboral creada")
      });
    },
    sendReferences(path, data, message){
      console.log("path ", path)
      console.log("data ", data)
      this.requestPost(this.url()+path, data).then(response => {
        if(response.status == 201){
          this.$vs.notify({color:'success',title:'Referencia creada',text: message, position: 'bottom-left'})
        }else{
          this.$vs.notify({color:'danger',title:'Ups',text:'No se creo la referencia.', position: 'bottom-left'})
        }
      }, (err) => {
        console.log("err ", err)
        this.$vs.notify({color:'danger',title:'Ups',text:'No se creo la referencia', position: 'bottom-left'})
      })
    },
    submitFormBasicInformation(){
      this.$validator.validateAll().then(result => {
        if(result) {
          // var candidate_id = this.candidate.id
          // console.log("candidate_id ", candidate_id)
          // var path = "/api/v1/candidates/"+candidate_id
          // this.requestPut(this.url()+path, this.candidate, this.headers()).then(response => {
          //   console.log(response)
          //   this.$vs.notify({color:'success',title:'Informacion  básica guardada',text:'Se ha guardado la información', position: 'bottom-left'})
          // }, (err) => {
          //   console.log('err users -> ' +  JSON.stringify(err))
          //   this.$vs.notify({color:'danger',title:'Ups',text:'No se guardo la información básica', position: 'bottom-left'})
          // })
        }else{
          // form have errors
        }
      })
    },
    submitFormFamilyReferences(){
      this.$validator.validateAll().then(result => {
        console.log("result", result)
        var formValid = true
        if(this.errors.has('family_reference_full_name')){
          formValid = false
        }
        if(this.errors.has('family_reference_age')){
          formValid = false
        }
        if(this.errors.has('family_reference_relationship')){
          formValid = false
        }
        if(this.errors.has('family_reference_telephone')){
          formValid = false
        }
        if(this.errors.has('family_reference_email')){
          formValid = false
        }
        if(this.errors.has('family_reference_city_id')){
          formValid = false
        }
        if(formValid){
          console.log("Form valid")
          var candidate_id = this.candidate.id
          var formFamilyReferencesCopy = {}
          formFamilyReferencesCopy = this.formFamilyReferences
          formFamilyReferencesCopy.candidate_id = candidate_id
          formFamilyReferencesCopy.family_reference_city_id = formFamilyReferencesCopy.family_reference_city_id.id
          this.family_references_add.push(formFamilyReferencesCopy)
          this.family_references.push(formFamilyReferencesCopy)
          this.formFamilyReferences = {
            family_reference_full_name: '',
            family_reference_age: '',
            family_reference_relationship: '',
            family_reference_telephone: '',
            family_reference_email: '',
            candidate_id: ''
          }
          this.resetErrorsForms()
        }else{
          console.log("Form invalid")
        }
      })
    },
    submitFormPersonalReferences(){
      this.$validator.validateAll().then(result => {
        console.log("result", result)
        var formValid = true
        if(this.errors.has('personal_reference_name')){
          formValid = false
        }
        if(this.errors.has('personal_reference_age')){
          formValid = false
        }
        if(this.errors.has('personal_reference_relation_type')){
          formValid = false
        }
        if(this.errors.has('personal_reference_telephone')){
          formValid = false
        }
        if(this.errors.has('personal_reference_email')){
          formValid = false
        }
        if(this.errors.has('personal_reference_city_id')){
          formValid = false
        }
        if(formValid){
          console.log("Form invalid")
          var candidate_id = this.candidate.id
          var formPersonalReferencesCopy = {}
          formPersonalReferencesCopy = this.formPersonalReferences
          formPersonalReferencesCopy.candidate_id = candidate_id
          formPersonalReferencesCopy.personal_reference_city_id = formPersonalReferencesCopy.personal_reference_city_id.id
          this.personal_references_add.push(formPersonalReferencesCopy)
          this.personal_references.push(formPersonalReferencesCopy)
          this.formPersonalReferences = {
            personal_reference_name: '',
            personal_reference_age: '',
            personal_reference_relation_type: '',
            personal_reference_telephone: '',
            personal_reference_email: '',
            personal_reference_city_id: '',
            candidate_id: ''
          }
          this.resetErrorsForms()
        }else{
          console.log("Form invalid")
        }
      })
    },
    submitFormAcademicReferencesBasic(){
      alert("submitFormAcademicReferencesBasic")
      this.$validator.validateAll().then(result => {
        console.log("result", result)
        var formValid = true
        if(this.errors.has('name_institution_basic')){
          formValid = false
        }
        if(this.errors.has('name_title_basic')){
          formValid = false
        }
        if(this.errors.has('date_graduation_basic')){
          formValid = false
        }
        if(this.errors.has('city_id_basic')){
          formValid = false
        }
        if(formValid){
          console.log("Form valid")
          var candidate_id = this.candidate.id
          var formAcademicReferencesBasicCopy = {}
          formAcademicReferencesBasicCopy = this.formAcademicReferencesBasic
          formAcademicReferencesBasicCopy.candidate_id = candidate_id
          formAcademicReferencesBasicCopy.city_id = formAcademicReferencesBasicCopy.city_id.id
          this.academic_references_basic_add.push(formAcademicReferencesBasicCopy)
          this.academic_references_basic.push(formAcademicReferencesBasicCopy)
          this.formAcademicReferencesBasic = {
            name_institution: '',
            name_title: '',
            date_graduation: '',
            city_id: '',
            observations: '',
            project_id: '',
            candidate_id: '',
            academic_type: 0
          }
          this.resetErrorsForms()
        }else{
          console.log("Form invalid")
        }
      })
    },
    submitFormAcademicReferencesSuperior(){
      this.$validator.validateAll().then(result => {
        console.log("result", result)
        var formValid = true
        if(this.errors.has('name_institution')){
          formValid = false
        }
        if(this.errors.has('name_title')){
          formValid = false
        }
        if(this.errors.has('date_graduation')){
          formValid = false
        }
        if(this.errors.has('city_id')){
          formValid = false
        }
        if(formValid){
          console.log("Form valid")
          var candidate_id = this.candidate.id
          var formAcademicReferencesSuperiorCopy = {}
          formAcademicReferencesSuperiorCopy = this.formAcademicReferencesSuperior
          formAcademicReferencesSuperiorCopy.candidate_id = candidate_id
          formAcademicReferencesSuperiorCopy.city_id = formAcademicReferencesSuperiorCopy.city_id.id
          this.academic_references_superior_add.push(formAcademicReferencesSuperiorCopy)
          this.academic_references_superior.push(formAcademicReferencesSuperiorCopy)
          this.formAcademicReferencesSuperior = {
            name_institution: '',
            name_title: '',
            date_graduation: '',
            city_id: '',
            observations: '',
            project_id: '',
            candidate_id: '',
            academic_type: 1
          }
          this.resetErrorsForms()
        }else{
          console.log("Form invalid")
        }
      })
    },
    submitFormLaboralReferences(){
      this.$validator.validateAll().then(result => {
        console.log("result", result)
        var formValid = true
        if(this.errors.has('company_name')){
          formValid = false
        }
        if(this.errors.has('charge')){
          formValid = false
        }
        if(this.errors.has('start_at')){
          formValid = false
        }
        if(this.errors.has('leave_at')){
          formValid = false
        }
        if(this.errors.has('contact_name')){
          formValid = false
        }
        if(this.errors.has('contact_charge')){
          formValid = false
        }
        if(this.errors.has('contact_telephone')){
          formValid = false
        }
        if(this.errors.has('salary')      ){
          formValid = false
        }
        if(formValid){
          console.log("Form valid")
          var candidate_id = this.candidate.id
          var formLaboralReferencesCopy = {}
          formLaboralReferencesCopy = this.formLaboralReferences
          formLaboralReferencesCopy.candidate_id = candidate_id
          this.laboral_references_add.push(formLaboralReferencesCopy)
          this.laboral_references.push(formLaboralReferencesCopy)
          this.formLaboralReferences = {
            company_name: '', // add
            charge: '', // add
            start_at: '', // add
            leave_at: '', // add
            contact_name: '', // add
            contact_charge: '', // add
            contact_telephone: '', // add
            salary: '', // add
            contract: '', // add
            calls: '', // add
            retirement: '', // add
            email: '',
            candidate_id: ''
          }
          this.resetErrorsForms()
        }else{
          console.log("Form invalid")
        }
      })
    },
    resetErrorsForms(){
      this.$validator.validateAll().then(result => {
        console.log("result ", result)
        this.errors["items"] = []
      })
    },
    openLegal(type){
      if (type == 1) { // 1 terms and condition
        this.titleLegal = 'TÉRMINOS Y CONDICIONES DE USO DE LA PLATAFORMA WEB DE GLOBALWORK COLOMBIA S.A.S.',
        this.document = 'https://api-verificaciones.globalwork.co/terminosycondiciones_globalwork.pdf'
      }
      if (type == 2) { //privacy policy
        this.titleLegal = 'POLÍTICAS DE PRIVACIDAD Y USO DE LA INFORMACIÓN DE LA PLATAFORMA WEB GLOBALWORK',
        this.document = 'https://api-verificaciones.globalwork.co//politicadeprivacidad_globalwork.pdf'
      }
      this.popupLegal = true
    }
  },
  mounted() {
    var token = this.$route.query.token
    console.log("this.$route.query ", this.$route.query)
    console.log("token ", token)
    var path = "/api/v1/clients/decode_token_candidate?token=" + token
    if (token != null){
      this.requestGetWithoutContent(this.url()+path, {}).then(response => {
        if(response.data.success){
          this.candidate = response.data.data.candidate;
          this.candidateName = this.candidate.first_name + " " + this.candidate.last_name
          this.family_references = this.candidate.family_references
          this.personal_references = this.candidate.personal_references
          this.academic_references_basic = this.candidate.academic_references_basic
          this.academic_references_superior = this.candidate.academic_references_superior
          this.laboral_references = this.candidate.laboral_references
        }else{
          console.log("No se pudieron obtener datos")
        }
      }, (err) => {
        console.log("err ", err)
      })
    }
  },
  components: {
    vSelect,
    Datepicker,
    BasicInformation,
    FamilyReferences,
    AcademicReferencesBasic,
    AcademicReferencesSuperior,
    LaboralReferences,
    PersonalReferences,
    FormWizard,
    TabContent
  }

}
</script>

<style>
  .name-candidate{
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .vue-form-wizard{
    /* position: absolute !important; */
    top: 50px !important;
    width: 100%
  }
</style>
