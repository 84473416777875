<template>
  <div class="basic-information">
    <vs-row class="title">
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
        <h3>
          {{titleForm}}
        </h3>
      </vs-col>
    </vs-row>
    <br>    
    <vs-row>
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
        Compártenos tres referencias familiares que nos puedan hablar sobre ti.
      </vs-col>
    </vs-row>       
    <br>        
    <form>

      <div class="vx-row">              
        <div class="vx-col md:w-1/4 w-full">
          <label v-bind:style="colorLabel(errors.has('family_reference_full_name'))">Nombre completo*</label>
          <vs-input size="large" v-validate="'required'" placeholder="Nombre completo" name="family_reference_full_name" v-model="form.family_reference_full_name" />          
        </div>
        <div class="vx-col md:w-1/4 w-full">
          <label v-bind:style="colorLabel(errors.has('family_reference_age'))">Edad*</label>
          <vs-input type="number" size="large" v-validate="'required'" placeholder="Edad" name="family_reference_age" v-model="form.family_reference_age" />          
        </div>
        <div class="vx-col md:w-1/5 w-full">
          <label v-bind:style="colorLabel(errors.has('family_reference_relationship'))">Parentesco*</label>
          <vs-select v-model="form.family_reference_relationship" class="w-full select-large mt-5 select-simple" name="family_reference_relationship">
            <vs-select-item :key="index" :value="item.id" :text="item.label" v-for="(item,index) in relations" />
          </vs-select>                  
        </div>                
        <div class="vx-col md:w-1/4 w-full">
          <label v-bind:style="colorLabel(errors.has('family_reference_telephone'))">Teléfono*</label>
          <vs-input size="large" v-validate="'required'" placeholder="Teléfono" name="family_reference_telephone" v-model="form.family_reference_telephone" />          
        </div>        
      </div>            

      <div class="vx-row">              
        <div class="vx-col md:w-1/4 w-full">
          <label v-bind:style="colorLabel(errors.has('family_reference_email'))">Email*</label>
          <vs-input size="large" v-validate="'required'" placeholder="Nombre completo" name="family_reference_email" v-model="form.family_reference_email" />          
        </div>
        <div class="vx-col md:w-1/4 w-full">
          <label v-bind:style="colorLabel(errors.has('family_reference_city_id'))">Ciudad de residencia*</label>
          <v-select v-model="form.family_reference_city_id" :options="cities()" v-validate="'required'" name="family_reference_city_id" ></v-select>                              
        </div>                                      
      </div>

      <!-- button sumit -->
      <vs-row class="row-btn-save">
        <vs-col vs-offset="10"  vs-type="flex" vs-justify="center" vs-align="center" vs-w="2">
          <vs-button type="filled" @click.prevent="submitForm" class="mt-5 block">Guardar</vs-button>
        </vs-col>
      </vs-row>      
    </form>
    <div class="vx-row">
      <div class="vx-col w-full lg:w-1/1 mb-base">
        <table class="table-references">
          <tr class="row-table">
            <th class="row-content">Nombre</th>
            <th class="row-content">Edad</th>
            <th class="row-content">Parentesco</th>
            <th class="row-content">Teléfono</th>
            <th class="row-content">Email</th>
            <!-- <th class="row-content">Ciudad</th>             -->
          </tr>
          <tr class="row-table" v-for="(family_reference, index) in family_references" :key="index">
            <td class="row-content">{{family_reference.family_reference_full_name}}</td>
            <td class="row-content">{{family_reference.family_reference_age}}</td>
            <td class="row-content">{{family_reference.family_reference_relationship}}</td>
            <td class="row-content">{{family_reference.family_reference_telephone}}</td>
            <td class="row-content">{{family_reference.family_reference_email}}</td>
            <!-- <td class="row-content">{{family_reference.family_reference_city_id}}</td> -->
          </tr>
        </table>
      </div>
    </div>    
  </div>
</template>

<script>
import vSelect from 'vue-select';
import Datepicker from 'vuejs-datepicker';
import cities from '../../../mixins/cities.js'
import api from '../../../mixins/api.js'
export default {
  mixins: [cities, api],
  props: {
    candidate: Object
  },    
  data(){
    return{
      family_references: [],
      relations: [
        {id: null, label: 'Seleccione una opción'},
        {id: 7, label: 'Amigo/a'},
        {id: 0, label: 'Hermano/a'},
        {id: 1, label: 'Hijo/a'},
        {id: 2, label: 'Padre/Madre'},
        {id: 3, label: 'Tio/a'},
        {id: 4, label: 'Abuelo/a'},
        {id: 5, label: 'Primo/a'},
        {id: 6, label: 'Sobrino/a'},
        {id: 8, label: 'Esposo/a'},
        {id: 9, label: 'Suegra'},
        {id: 10, label: 'Cuñado'},
        {id: 11, label: 'Otro'},                
      ],
      idTypes: [
        {id: null, label: 'Seleccione una opción'},
        {id: 1, label: 'Cédula'},
        {id: 2, label: 'Tarjeta de identidad'},
        {id: 3, label: 'Pasaporte'},
        {id: 4, label: 'Cédula de extranjería'},
      ],        
      genders: [
        {id: null, label: 'Seleccione una opción'},
        {id: 0, label: 'Femenino'},
        {id: 1, label: 'Masculino'}
      ],
      civilTypes: [
        {id: null, label: 'Seleccione una opción'},
        {id: 0, label: 'Soltero/a'},
        {id: 1, label: 'Casado/a'},
        {id: 3, label: 'Viudo/a'},
        {id: 4, label: 'Unión libre'}
      ],
      bloodTypes: [
        {id: null, label: 'Seleccione una opción'},
        {id: 0, label: 'O-'},
        {id: 0, label: 'O+'},
        {id: 0, label: 'A-'},
        {id: 0, label: 'A+'},
        {id: 0, label: 'B-'},
        {id: 0, label: 'B+'},
        {id: 0, label: 'AB-'},
        {id: 0, label: 'AB+'}
      ],      
      titleForm: 'Referencias familiares',
      form: {
        family_reference_full_name: '',
        family_reference_age: '',
        family_reference_relationship: '',
        family_reference_telephone: '',
        family_reference_email: '',
        candidate_id: ''
      }
    }
  },
  methods: {
    colorLabel(errorLabel){
      var cadenaColor = 'color: black';
      if(errorLabel){
        cadenaColor = 'color: red';
      }
      return cadenaColor;
    },
    submitForm() {
      this.$validator.validateAll().then(result => {
        if(result) {
          var candidate_id = this.candidate.id
          this.form.candidate_id = candidate_id
          var path = "api/v1/family_references"
          this.form.family_reference_city_id = this.form.family_reference_city_id.id
          this.requestPost(this.url()+path, this.form).then(response => {
            if(response.status == 201){
              var family_reference = response.data
              this.family_references.push(family_reference)
              this.$vs.notify({color:'success',title:'Referencia familiar creada',text:'Se ha creado la referencia familiar', position: 'bottom-left'})          
            }else{
              this.$vs.notify({color:'danger',title:'Ups',text:'No se creo la referencia familiar', position: 'bottom-left'})      
            }
          }, (err) => {
            console.log("err ", err)
            this.$vs.notify({color:'danger',title:'Ups',text:'No se creo la referencia familiar', position: 'bottom-left'})      
          })          
        }else{
          // form have errors
        }
      })
    }        
  },
  mounted() {
    console.log("****this.candidate**** ", JSON.stringify(this.candidate))
    this.family_references = this.candidate.family_references
  },    
  components: {
    vSelect,
    Datepicker
  }

}
</script>

<style>
  /* .basic-information{
    background: #ffffff;
    margin-left: 5rem;
    margin-right: 5rem;
    border-radius: 10px;
    height: 42rem;
  } */
  .title{
    padding-top: 1rem;
  }
  .row-btn-save{
    padding-bottom: 1rem;
  }
  .message-field-require{
    font-size: 2rem !important;
  }
  .vs__search{
    height: 38px !important
  }
  .select-simple{
    margin-top: 0rem !important;
  }
  .select-large input.vs-select--input{
    height: 49px !important;
  }
  .vdp-datepicker input{
    height: 49px !important;
  }
  .vx-row{
    margin: 0;
    padding-top: 1rem;
  }
  .vs-con-input-label{
    width: 100%;
  }
  .vdp-datepicker input{
    width: 100%;
  }

  .table-references {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    background: #ffffff;
  }

  .row-content {
    /* border: 1px solid #bfbfbf; */
    text-align: left;
    padding: 8px;
  }

  .row-table:nth-child(even) {
    background-color: #dddddd;
  }
</style>