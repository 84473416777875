var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c(
        "vs-popup",
        {
          attrs: {
            title: "Mensaje de Globalwork",
            active: _vm.modalMessageSaveInformation,
          },
          on: {
            "update:active": function ($event) {
              _vm.modalMessageSaveInformation = $event
            },
          },
        },
        [
          _c("p", [
            _vm._v("\n      " + _vm._s(_vm.messageSaveInformation) + "\n    "),
          ]),
        ]
      ),
      _c(
        "vs-popup",
        {
          attrs: {
            fullscreen: "",
            title: _vm.titleLegal,
            active: _vm.popupLegal,
          },
          on: {
            "update:active": function ($event) {
              _vm.popupLegal = $event
            },
          },
        },
        [
          _c("iframe", {
            attrs: {
              src: _vm.document,
              frameborder: "0",
              width: "100%",
              height: "1000",
              marginheight: "0",
              marginwidth: "0",
              id: "pdf",
            },
          }),
        ]
      ),
      _c(
        "vs-col",
        {
          staticClass: "name-candidate",
          attrs: {
            "vs-type": "flex",
            "vs-justify": "center",
            "vs-align": "center",
            "vs-w": "12",
          },
        },
        [
          _c(
            "form-wizard",
            {
              attrs: {
                color: "rgba(var(--vs-primary), 1)",
                errorColor: "rgba(var(--vs-danger), 1)",
                title: _vm.candidateName,
                subtitle: "Por favor llena todos los campos",
                finishButtonText: "Guardar información",
                backButtonText: "anterior",
                nextButtonText: "Siguiente",
              },
            },
            [
              _c(
                "tab-content",
                {
                  staticClass: "mb-5",
                  attrs: {
                    title: "Términos y condiciones",
                    icon: "feather icon-file-text",
                    "before-change": _vm.validatePolicies,
                  },
                },
                [
                  _c(
                    "vs-row",
                    [
                      _c(
                        "vs-col",
                        {
                          attrs: {
                            "vs-offset": "4",
                            "vs-type": "flex",
                            "vs-justify": "center",
                            "vs-align": "center",
                            "vs-w": "4",
                          },
                        },
                        [
                          _c("vs-checkbox", {
                            model: {
                              value: _vm.candidate.terms,
                              callback: function ($$v) {
                                _vm.$set(_vm.candidate, "terms", $$v)
                              },
                              expression: "candidate.terms",
                            },
                          }),
                          _c(
                            "vs-button",
                            {
                              attrs: { color: "primary", type: "flat" },
                              on: {
                                click: function ($event) {
                                  return _vm.openLegal(1)
                                },
                              },
                            },
                            [_vm._v("Términos y condiciones")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("br"),
                  _c(
                    "vs-row",
                    [
                      _c(
                        "vs-col",
                        {
                          attrs: {
                            "vs-offset": "4",
                            "vs-type": "flex",
                            "vs-justify": "center",
                            "vs-align": "center",
                            "vs-w": "4",
                          },
                        },
                        [
                          _c("vs-checkbox", {
                            model: {
                              value: _vm.candidate.privacy,
                              callback: function ($$v) {
                                _vm.$set(_vm.candidate, "privacy", $$v)
                              },
                              expression: "candidate.privacy",
                            },
                          }),
                          _c(
                            "vs-button",
                            {
                              attrs: { color: "primary", type: "flat" },
                              on: {
                                click: function ($event) {
                                  return _vm.openLegal(2)
                                },
                              },
                            },
                            [_vm._v("Política de privacidad")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "tab-content",
                {
                  staticClass: "mb-5",
                  attrs: {
                    title: "Información básica",
                    icon: "feather icon-home",
                    "before-change": _vm.validateInformationBasic,
                  },
                },
                [
                  _c(
                    "vs-row",
                    { staticClass: "title" },
                    [
                      _c(
                        "vs-col",
                        {
                          attrs: {
                            "vs-type": "flex",
                            "vs-justify": "center",
                            "vs-align": "center",
                            "vs-w": "6",
                          },
                        },
                        [
                          _c("h3", [
                            _vm._v(
                              "\n              Información básica\n            "
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c("br"),
                  _c(
                    "vs-row",
                    [
                      _c(
                        "vs-col",
                        {
                          attrs: {
                            "vs-type": "flex",
                            "vs-justify": "center",
                            "vs-align": "center",
                            "vs-w": "12",
                          },
                        },
                        [
                          _vm._v(
                            "\n            Cuéntanos sobre ti.\n          "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c("br"),
                  _c("form", [
                    _c("div", { staticClass: "vx-row" }, [
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/3 w-full" },
                        [
                          _c(
                            "label",
                            {
                              style: _vm.colorLabel(
                                _vm.errors.has("first_name")
                              ),
                            },
                            [_vm._v("Nombre Aspirante*")]
                          ),
                          _c("vs-input", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'",
                              },
                            ],
                            attrs: {
                              size: "large",
                              placeholder: "Nombre aspirante",
                              name: "first_name",
                            },
                            model: {
                              value: _vm.candidate.first_name,
                              callback: function ($$v) {
                                _vm.$set(_vm.candidate, "first_name", $$v)
                              },
                              expression: "candidate.first_name",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/3 w-full" },
                        [
                          _c(
                            "label",
                            {
                              style: _vm.colorLabel(
                                _vm.errors.has("last_name")
                              ),
                            },
                            [_vm._v("Apellidos aspirante*")]
                          ),
                          _c("vs-input", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'",
                              },
                            ],
                            attrs: {
                              size: "large",
                              placeholder: "Apellidos aspirante",
                              name: "last_name",
                            },
                            model: {
                              value: _vm.candidate.last_name,
                              callback: function ($$v) {
                                _vm.$set(_vm.candidate, "last_name", $$v)
                              },
                              expression: "candidate.last_name",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/3 w-full" },
                        [
                          _c(
                            "label",
                            {
                              style: _vm.colorLabel(
                                _vm.errors.has("birth_date")
                              ),
                            },
                            [_vm._v("Fecha de nacimiento*")]
                          ),
                          _c("datepicker", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'",
                              },
                            ],
                            attrs: {
                              placeholder: "Fecha de nacimiento",
                              name: "birth_date",
                            },
                            model: {
                              value: _vm.candidate.birth_date,
                              callback: function ($$v) {
                                _vm.$set(_vm.candidate, "birth_date", $$v)
                              },
                              expression: "candidate.birth_date",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "vx-row" }, [
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/3 w-full" },
                        [
                          _c(
                            "label",
                            { style: _vm.colorLabel(_vm.errors.has("gender")) },
                            [_vm._v("Género*")]
                          ),
                          _c(
                            "vs-select",
                            {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'",
                                },
                              ],
                              staticClass:
                                "w-full select-large mt-5 select-simple",
                              attrs: { name: "gender" },
                              model: {
                                value: _vm.candidate.gender,
                                callback: function ($$v) {
                                  _vm.$set(_vm.candidate, "gender", $$v)
                                },
                                expression: "candidate.gender",
                              },
                            },
                            _vm._l(_vm.genders, function (item, index) {
                              return _c("vs-select-item", {
                                key: index,
                                attrs: { value: item.id, text: item.label },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/3 w-full" },
                        [
                          _c(
                            "label",
                            {
                              style: _vm.colorLabel(
                                _vm.errors.has("civil_status")
                              ),
                            },
                            [_vm._v("Estado civil*")]
                          ),
                          _c(
                            "vs-select",
                            {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'",
                                },
                              ],
                              staticClass:
                                "w-full select-large mt-5 select-simple",
                              attrs: { name: "civil_status" },
                              model: {
                                value: _vm.candidate.civil_status,
                                callback: function ($$v) {
                                  _vm.$set(_vm.candidate, "civil_status", $$v)
                                },
                                expression: "candidate.civil_status",
                              },
                            },
                            _vm._l(_vm.civilTypes, function (item, index) {
                              return _c("vs-select-item", {
                                key: index,
                                attrs: { value: item.id, text: item.label },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/3 w-full" },
                        [
                          _c(
                            "label",
                            {
                              style: _vm.colorLabel(
                                _vm.errors.has("blood_type")
                              ),
                            },
                            [_vm._v("Grupo sanguíneo*")]
                          ),
                          _c(
                            "vs-select",
                            {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'",
                                },
                              ],
                              staticClass:
                                "w-full select-large mt-5 select-simple",
                              attrs: { name: "bloodTypes" },
                              model: {
                                value: _vm.candidate.blood_type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.candidate, "blood_type", $$v)
                                },
                                expression: "candidate.blood_type",
                              },
                            },
                            _vm._l(_vm.bloodTypes, function (item, index) {
                              return _c("vs-select-item", {
                                key: index,
                                attrs: { value: item.id, text: item.label },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "vx-row" }, [
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/4 w-full" },
                        [
                          _c(
                            "label",
                            {
                              style: _vm.colorLabel(
                                _vm.errors.has("current_activity")
                              ),
                            },
                            [_vm._v("Actividad actual*")]
                          ),
                          _c("vs-input", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'",
                              },
                            ],
                            attrs: {
                              size: "large",
                              placeholder: "Actividad actual",
                              name: "current_activity",
                            },
                            model: {
                              value: _vm.candidate.current_activity,
                              callback: function ($$v) {
                                _vm.$set(_vm.candidate, "current_activity", $$v)
                              },
                              expression: "candidate.current_activity",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/4 w-full" },
                        [
                          _c(
                            "label",
                            {
                              style: _vm.colorLabel(_vm.errors.has("city_id")),
                            },
                            [_vm._v("Ciudad de residencia*")]
                          ),
                          _c("v-select", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'",
                              },
                            ],
                            attrs: { options: _vm.cities(), name: "city_id" },
                            model: {
                              value: _vm.candidate.city_id,
                              callback: function ($$v) {
                                _vm.$set(_vm.candidate, "city_id", $$v)
                              },
                              expression: "candidate.city_id",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/4 w-full" },
                        [
                          _c(
                            "label",
                            {
                              style: _vm.colorLabel(_vm.errors.has("location")),
                            },
                            [_vm._v("Localidad / Barrio*")]
                          ),
                          _c("vs-input", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'",
                              },
                            ],
                            attrs: {
                              size: "large",
                              placeholder: "Localidad",
                              name: "location",
                            },
                            model: {
                              value: _vm.candidate.location,
                              callback: function ($$v) {
                                _vm.$set(_vm.candidate, "location", $$v)
                              },
                              expression: "candidate.location",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/4 w-full" },
                        [
                          _c(
                            "label",
                            {
                              style: _vm.colorLabel(_vm.errors.has("address")),
                            },
                            [_vm._v("Dirección*")]
                          ),
                          _c("vs-input", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'",
                              },
                            ],
                            attrs: {
                              size: "large",
                              placeholder: "Dirección",
                              name: "address",
                            },
                            model: {
                              value: _vm.candidate.address,
                              callback: function ($$v) {
                                _vm.$set(_vm.candidate, "address", $$v)
                              },
                              expression: "candidate.address",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "vx-row" }, [
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/4 w-full" },
                        [
                          _c(
                            "label",
                            {
                              style: _vm.colorLabel(
                                _vm.errors.has("document_type")
                              ),
                            },
                            [_vm._v("Tipo de documento*")]
                          ),
                          _c(
                            "vs-select",
                            {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'",
                                },
                              ],
                              staticClass:
                                "w-full select-large mt-5 select-simple",
                              attrs: { name: "document_type" },
                              model: {
                                value: _vm.candidate.document_type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.candidate, "document_type", $$v)
                                },
                                expression: "candidate.document_type",
                              },
                            },
                            _vm._l(_vm.idTypes, function (item, index) {
                              return _c("vs-select-item", {
                                key: index,
                                attrs: { value: item.id, text: item.label },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/4 w-full" },
                        [
                          _c(
                            "label",
                            {
                              style: _vm.colorLabel(
                                _vm.errors.has("person_id")
                              ),
                            },
                            [_vm._v("Cédula de ciudadanía*")]
                          ),
                          _c("vs-input", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'",
                              },
                            ],
                            attrs: {
                              size: "large",
                              placeholder: "Cédula de ciudadanía",
                              name: "person_id",
                            },
                            model: {
                              value: _vm.candidate.person_id,
                              callback: function ($$v) {
                                _vm.$set(_vm.candidate, "person_id", $$v)
                              },
                              expression: "candidate.person_id",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/4 w-full" },
                        [
                          _c(
                            "label",
                            { style: _vm.colorLabel(_vm.errors.has("email")) },
                            [_vm._v("Email*")]
                          ),
                          _c("vs-input", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'",
                              },
                            ],
                            attrs: {
                              size: "large",
                              placeholder: "Email",
                              name: "email",
                            },
                            model: {
                              value: _vm.candidate.email,
                              callback: function ($$v) {
                                _vm.$set(_vm.candidate, "email", $$v)
                              },
                              expression: "candidate.email",
                            },
                          }),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("candidate.email"),
                                  expression: "errors.has('candidate.email')",
                                },
                              ],
                              staticClass: "text-danger message-field-require",
                            },
                            [_vm._v("*")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/4 w-full" },
                        [
                          _c(
                            "label",
                            { style: _vm.colorLabel(_vm.errors.has("phone")) },
                            [_vm._v("Teléfono*")]
                          ),
                          _c("vs-input", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'",
                              },
                            ],
                            attrs: {
                              size: "large",
                              placeholder: "Teléfono",
                              name: "phone",
                            },
                            model: {
                              value: _vm.candidate.phone,
                              callback: function ($$v) {
                                _vm.$set(_vm.candidate, "phone", $$v)
                              },
                              expression: "candidate.phone",
                            },
                          }),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("candidate.phone"),
                                  expression: "errors.has('candidate.phone')",
                                },
                              ],
                              staticClass: "text-danger message-field-require",
                            },
                            [_vm._v("*")]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "tab-content",
                {
                  staticClass: "mb-5",
                  attrs: {
                    title: "Referencias familiares",
                    icon: "feather icon-users",
                    "before-change": _vm.validatesFamilyReferences,
                  },
                },
                [
                  _c(
                    "vs-row",
                    { staticClass: "title" },
                    [
                      _c(
                        "vs-col",
                        {
                          attrs: {
                            "vs-type": "flex",
                            "vs-justify": "center",
                            "vs-align": "center",
                            "vs-w": "6",
                          },
                        },
                        [
                          _c("h3", [
                            _vm._v(
                              "\n              Referencias familiares\n            "
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c("br"),
                  _c(
                    "vs-row",
                    [
                      _c(
                        "vs-col",
                        {
                          attrs: {
                            "vs-type": "flex",
                            "vs-justify": "center",
                            "vs-align": "center",
                            "vs-w": "12",
                          },
                        },
                        [
                          _vm._v(
                            "\n            Compártenos tres referencias familiares que nos puedan hablar sobre ti.\n          "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c("br"),
                  _c(
                    "form",
                    [
                      _c("div", { staticClass: "vx-row" }, [
                        _c(
                          "div",
                          { staticClass: "vx-col md:w-1/4 w-full" },
                          [
                            _c(
                              "label",
                              {
                                style: _vm.colorLabel(
                                  _vm.errors.has("family_reference_full_name")
                                ),
                              },
                              [_vm._v("Nombre completo*")]
                            ),
                            _c("vs-input", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'",
                                },
                              ],
                              attrs: {
                                size: "large",
                                placeholder: "Nombre completo",
                                name: "family_reference_full_name",
                              },
                              model: {
                                value:
                                  _vm.formFamilyReferences
                                    .family_reference_full_name,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formFamilyReferences,
                                    "family_reference_full_name",
                                    $$v
                                  )
                                },
                                expression:
                                  "formFamilyReferences.family_reference_full_name",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "vx-col md:w-1/4 w-full" },
                          [
                            _c(
                              "label",
                              {
                                style: _vm.colorLabel(
                                  _vm.errors.has("family_reference_age")
                                ),
                              },
                              [_vm._v("Edad*")]
                            ),
                            _c("vs-input", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'",
                                },
                              ],
                              attrs: {
                                type: "number",
                                size: "large",
                                placeholder: "Edad",
                                name: "family_reference_age",
                              },
                              model: {
                                value:
                                  _vm.formFamilyReferences.family_reference_age,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formFamilyReferences,
                                    "family_reference_age",
                                    $$v
                                  )
                                },
                                expression:
                                  "formFamilyReferences.family_reference_age",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "vx-col md:w-1/5 w-full" },
                          [
                            _c(
                              "label",
                              {
                                style: _vm.colorLabel(
                                  _vm.errors.has(
                                    "family_reference_relationship"
                                  )
                                ),
                              },
                              [_vm._v("Parentesco*")]
                            ),
                            _c(
                              "vs-select",
                              {
                                staticClass:
                                  "w-full select-large mt-5 select-simple",
                                attrs: {
                                  name: "family_reference_relationship",
                                },
                                model: {
                                  value:
                                    _vm.formFamilyReferences
                                      .family_reference_relationship,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formFamilyReferences,
                                      "family_reference_relationship",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "formFamilyReferences.family_reference_relationship",
                                },
                              },
                              _vm._l(_vm.relations, function (item, index) {
                                return _c("vs-select-item", {
                                  key: index,
                                  attrs: { value: item.id, text: item.label },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "vx-col md:w-1/4 w-full" },
                          [
                            _c(
                              "label",
                              {
                                style: _vm.colorLabel(
                                  _vm.errors.has("family_reference_telephone")
                                ),
                              },
                              [_vm._v("Teléfono*")]
                            ),
                            _c("vs-input", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'",
                                },
                              ],
                              attrs: {
                                size: "large",
                                placeholder: "Teléfono",
                                name: "family_reference_telephone",
                              },
                              model: {
                                value:
                                  _vm.formFamilyReferences
                                    .family_reference_telephone,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formFamilyReferences,
                                    "family_reference_telephone",
                                    $$v
                                  )
                                },
                                expression:
                                  "formFamilyReferences.family_reference_telephone",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "vx-row" }, [
                        _c(
                          "div",
                          { staticClass: "vx-col md:w-1/4 w-full" },
                          [
                            _c(
                              "label",
                              {
                                style: _vm.colorLabel(
                                  _vm.errors.has("family_reference_email")
                                ),
                              },
                              [_vm._v("Email*")]
                            ),
                            _c("vs-input", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'",
                                },
                              ],
                              attrs: {
                                size: "large",
                                placeholder: "Nombre completo",
                                name: "family_reference_email",
                              },
                              model: {
                                value:
                                  _vm.formFamilyReferences
                                    .family_reference_email,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formFamilyReferences,
                                    "family_reference_email",
                                    $$v
                                  )
                                },
                                expression:
                                  "formFamilyReferences.family_reference_email",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "vx-col md:w-1/4 w-full" },
                          [
                            _c(
                              "label",
                              {
                                style: _vm.colorLabel(
                                  _vm.errors.has("family_reference_city_id")
                                ),
                              },
                              [_vm._v("Ciudad de residencia*")]
                            ),
                            _c("v-select", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'",
                                },
                              ],
                              attrs: {
                                options: _vm.cities(),
                                name: "family_reference_city_id",
                              },
                              model: {
                                value:
                                  _vm.formFamilyReferences
                                    .family_reference_city_id,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formFamilyReferences,
                                    "family_reference_city_id",
                                    $$v
                                  )
                                },
                                expression:
                                  "formFamilyReferences.family_reference_city_id",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c(
                        "vs-row",
                        { staticClass: "row-btn-save" },
                        [
                          _c(
                            "vs-col",
                            {
                              attrs: {
                                "vs-offset": "10",
                                "vs-type": "flex",
                                "vs-justify": "center",
                                "vs-align": "center",
                                "vs-w": "2",
                              },
                            },
                            [
                              _c(
                                "vs-button",
                                {
                                  staticClass: "mt-5 block",
                                  attrs: { type: "filled" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.submitFormFamilyReferences.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [_vm._v("Agregar")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "vx-row" }, [
                    _c(
                      "div",
                      { staticClass: "vx-col w-full lg:w-1/1 mb-base" },
                      [
                        _c(
                          "table",
                          { staticClass: "table-references" },
                          [
                            _c("tr", { staticClass: "row-table" }, [
                              _c("th", { staticClass: "row-content" }, [
                                _vm._v("Nombre"),
                              ]),
                              _c("th", { staticClass: "row-content" }, [
                                _vm._v("Edad"),
                              ]),
                              _c("th", { staticClass: "row-content" }, [
                                _vm._v("Parentesco"),
                              ]),
                              _c("th", { staticClass: "row-content" }, [
                                _vm._v("Teléfono"),
                              ]),
                              _c("th", { staticClass: "row-content" }, [
                                _vm._v("Email"),
                              ]),
                            ]),
                            _vm._l(
                              _vm.family_references,
                              function (family_reference, index) {
                                return _c(
                                  "tr",
                                  { key: index, staticClass: "row-table" },
                                  [
                                    _c("td", { staticClass: "row-content" }, [
                                      _vm._v(
                                        _vm._s(
                                          family_reference.family_reference_full_name
                                        )
                                      ),
                                    ]),
                                    _c("td", { staticClass: "row-content" }, [
                                      _vm._v(
                                        _vm._s(
                                          family_reference.family_reference_age
                                        )
                                      ),
                                    ]),
                                    _c("td", { staticClass: "row-content" }, [
                                      _vm._v(
                                        _vm._s(
                                          family_reference.family_reference_relationship
                                        )
                                      ),
                                    ]),
                                    _c("td", { staticClass: "row-content" }, [
                                      _vm._v(
                                        _vm._s(
                                          family_reference.family_reference_telephone
                                        )
                                      ),
                                    ]),
                                    _c("td", { staticClass: "row-content" }, [
                                      _vm._v(
                                        _vm._s(
                                          family_reference.family_reference_email
                                        )
                                      ),
                                    ]),
                                  ]
                                )
                              }
                            ),
                          ],
                          2
                        ),
                      ]
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "tab-content",
                {
                  staticClass: "mb-5",
                  attrs: {
                    title: "Referencias personales",
                    icon: "feather icon-user-check",
                    "before-change": _vm.validatesPersonalReferences,
                  },
                },
                [
                  _c(
                    "vs-row",
                    { staticClass: "title" },
                    [
                      _c(
                        "vs-col",
                        {
                          attrs: {
                            "vs-type": "flex",
                            "vs-justify": "center",
                            "vs-align": "center",
                            "vs-w": "6",
                          },
                        },
                        [
                          _c("h3", [
                            _vm._v(
                              "\n              Referencias personales\n            "
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c("br"),
                  _c(
                    "vs-row",
                    [
                      _c(
                        "vs-col",
                        {
                          attrs: {
                            "vs-type": "flex",
                            "vs-justify": "center",
                            "vs-align": "center",
                            "vs-w": "12",
                          },
                        },
                        [
                          _vm._v(
                            "\n            Dinos a qué amigos o compañeros podemos contactar.\n          "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c("br"),
                  _c(
                    "form",
                    [
                      _c("div", { staticClass: "vx-row" }, [
                        _c(
                          "div",
                          { staticClass: "vx-col md:w-1/4 w-full" },
                          [
                            _c(
                              "label",
                              {
                                style: _vm.colorLabel(
                                  _vm.errors.has("personal_reference_name")
                                ),
                              },
                              [_vm._v("Nombre completo*")]
                            ),
                            _c("vs-input", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'",
                                },
                              ],
                              attrs: {
                                size: "large",
                                placeholder: "Nombre completo",
                                name: "personal_reference_name",
                              },
                              model: {
                                value:
                                  _vm.formPersonalReferences
                                    .personal_reference_name,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formPersonalReferences,
                                    "personal_reference_name",
                                    $$v
                                  )
                                },
                                expression:
                                  "formPersonalReferences.personal_reference_name",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "vx-col md:w-1/4 w-full" },
                          [
                            _c(
                              "label",
                              {
                                style: _vm.colorLabel(
                                  _vm.errors.has("personal_reference_age")
                                ),
                              },
                              [_vm._v("Edad*")]
                            ),
                            _c("vs-input", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'",
                                },
                              ],
                              attrs: {
                                type: "number",
                                size: "large",
                                placeholder: "Edad",
                                name: "personal_reference_age",
                              },
                              model: {
                                value:
                                  _vm.formPersonalReferences
                                    .personal_reference_age,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formPersonalReferences,
                                    "personal_reference_age",
                                    $$v
                                  )
                                },
                                expression:
                                  "formPersonalReferences.personal_reference_age",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "vx-col md:w-1/5 w-full" },
                          [
                            _c(
                              "label",
                              {
                                style: _vm.colorLabel(
                                  _vm.errors.has(
                                    "personal_reference_relation_type"
                                  )
                                ),
                              },
                              [_vm._v("Tipo de relación*")]
                            ),
                            _c(
                              "vs-select",
                              {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required",
                                    expression: "'required'",
                                  },
                                ],
                                staticClass:
                                  "w-full select-large mt-5 select-simple",
                                attrs: {
                                  name: "personal_reference_relation_type",
                                },
                                model: {
                                  value:
                                    _vm.formPersonalReferences
                                      .personal_reference_relation_type,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formPersonalReferences,
                                      "personal_reference_relation_type",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "formPersonalReferences.personal_reference_relation_type",
                                },
                              },
                              _vm._l(_vm.relations, function (item, index) {
                                return _c("vs-select-item", {
                                  key: index,
                                  attrs: { value: item.id, text: item.label },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "vx-col md:w-1/4 w-full" },
                          [
                            _c(
                              "label",
                              {
                                style: _vm.colorLabel(
                                  _vm.errors.has("personal_reference_telephone")
                                ),
                              },
                              [_vm._v("Teléfono*")]
                            ),
                            _c("vs-input", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'",
                                },
                              ],
                              attrs: {
                                size: "large",
                                placeholder: "Teléfono",
                                name: "personal_reference_telephone",
                              },
                              model: {
                                value:
                                  _vm.formPersonalReferences
                                    .personal_reference_telephone,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formPersonalReferences,
                                    "personal_reference_telephone",
                                    $$v
                                  )
                                },
                                expression:
                                  "formPersonalReferences.personal_reference_telephone",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "vx-row" }, [
                        _c(
                          "div",
                          { staticClass: "vx-col md:w-1/4 w-full" },
                          [
                            _c(
                              "label",
                              {
                                style: _vm.colorLabel(
                                  _vm.errors.has("personal_reference_email")
                                ),
                              },
                              [_vm._v("Email*")]
                            ),
                            _c("vs-input", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'",
                                },
                              ],
                              attrs: {
                                size: "large",
                                placeholder: "Nombre completo",
                                name: "personal_reference_email",
                              },
                              model: {
                                value:
                                  _vm.formPersonalReferences
                                    .personal_reference_email,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formPersonalReferences,
                                    "personal_reference_email",
                                    $$v
                                  )
                                },
                                expression:
                                  "formPersonalReferences.personal_reference_email",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "vx-col md:w-1/4 w-full" },
                          [
                            _c(
                              "label",
                              {
                                style: _vm.colorLabel(
                                  _vm.errors.has("personal_reference_city_id")
                                ),
                              },
                              [_vm._v("Ciudad de residencia*")]
                            ),
                            _c("v-select", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'",
                                },
                              ],
                              attrs: {
                                options: _vm.cities(),
                                name: "personal_reference_city_id",
                              },
                              model: {
                                value:
                                  _vm.formPersonalReferences
                                    .personal_reference_city_id,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formPersonalReferences,
                                    "personal_reference_city_id",
                                    $$v
                                  )
                                },
                                expression:
                                  "formPersonalReferences.personal_reference_city_id",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c(
                        "vs-row",
                        { staticClass: "row-btn-save" },
                        [
                          _c(
                            "vs-col",
                            {
                              attrs: {
                                "vs-offset": "10",
                                "vs-type": "flex",
                                "vs-justify": "center",
                                "vs-align": "center",
                                "vs-w": "2",
                              },
                            },
                            [
                              _c(
                                "vs-button",
                                {
                                  staticClass: "mt-5 block",
                                  attrs: { type: "filled" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.submitFormPersonalReferences.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [_vm._v("Agregar")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "vx-row" }, [
                    _c(
                      "div",
                      { staticClass: "vx-col w-full lg:w-1/1 mb-base" },
                      [
                        _c(
                          "table",
                          { staticClass: "table-references" },
                          [
                            _c("tr", { staticClass: "row-table" }, [
                              _c("th", { staticClass: "row-content" }, [
                                _vm._v("Nombre"),
                              ]),
                              _c("th", { staticClass: "row-content" }, [
                                _vm._v("Edad"),
                              ]),
                              _c("th", { staticClass: "row-content" }, [
                                _vm._v("Parentesco"),
                              ]),
                              _c("th", { staticClass: "row-content" }, [
                                _vm._v("Teléfono"),
                              ]),
                              _c("th", { staticClass: "row-content" }, [
                                _vm._v("Email"),
                              ]),
                            ]),
                            _vm._l(
                              _vm.personal_references,
                              function (personal_reference, index) {
                                return _c(
                                  "tr",
                                  { key: index, staticClass: "row-table" },
                                  [
                                    _c("td", { staticClass: "row-content" }, [
                                      _vm._v(
                                        _vm._s(
                                          personal_reference.personal_reference_name
                                        )
                                      ),
                                    ]),
                                    _c("td", { staticClass: "row-content" }, [
                                      _vm._v(
                                        _vm._s(
                                          personal_reference.personal_reference_age
                                        )
                                      ),
                                    ]),
                                    _c("td", { staticClass: "row-content" }, [
                                      _vm._v(
                                        _vm._s(
                                          personal_reference.personal_reference_relation_type
                                        )
                                      ),
                                    ]),
                                    _c("td", { staticClass: "row-content" }, [
                                      _vm._v(
                                        _vm._s(
                                          personal_reference.personal_reference_telephone
                                        )
                                      ),
                                    ]),
                                    _c("td", { staticClass: "row-content" }, [
                                      _vm._v(
                                        _vm._s(
                                          personal_reference.personal_reference_email
                                        )
                                      ),
                                    ]),
                                  ]
                                )
                              }
                            ),
                          ],
                          2
                        ),
                      ]
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "tab-content",
                {
                  staticClass: "mb-5",
                  attrs: {
                    title: "Estudios básicos",
                    icon: "feather icon-book-open",
                    "before-change": _vm.validatesAcademicReferencesBasic,
                  },
                },
                [
                  _c(
                    "vs-row",
                    { staticClass: "title" },
                    [
                      _c(
                        "vs-col",
                        {
                          attrs: {
                            "vs-type": "flex",
                            "vs-justify": "center",
                            "vs-align": "center",
                            "vs-w": "6",
                          },
                        },
                        [
                          _c("h3", [
                            _vm._v(
                              "\n              Estudios básicos\n            "
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c("br"),
                  _c(
                    "vs-row",
                    [
                      _c(
                        "vs-col",
                        {
                          attrs: {
                            "vs-type": "flex",
                            "vs-justify": "center",
                            "vs-align": "center",
                            "vs-w": "12",
                          },
                        },
                        [
                          _vm._v(
                            "\n            Ingresa las instituciones educativas en las que estudiaste la primaria y el bachillerato.\n          "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c("br"),
                  _c(
                    "form",
                    [
                      _c("div", { staticClass: "vx-row" }, [
                        _c(
                          "div",
                          { staticClass: "vx-col md:w-1/4 w-full" },
                          [
                            _c(
                              "label",
                              {
                                style: _vm.colorLabel(
                                  _vm.errors.has("name_institution_basic")
                                ),
                              },
                              [_vm._v("Nombre institución*")]
                            ),
                            _c("vs-input", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'",
                                },
                              ],
                              attrs: {
                                size: "large",
                                placeholder: "Nombre institución",
                                name: "name_institution_basic",
                              },
                              model: {
                                value:
                                  _vm.formAcademicReferencesBasic
                                    .name_institution,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formAcademicReferencesBasic,
                                    "name_institution",
                                    $$v
                                  )
                                },
                                expression:
                                  "formAcademicReferencesBasic.name_institution",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "vx-col md:w-1/4 w-full" },
                          [
                            _c(
                              "label",
                              {
                                style: _vm.colorLabel(
                                  _vm.errors.has("name_title_basic")
                                ),
                              },
                              [_vm._v("Título obtenido*")]
                            ),
                            _c("vs-input", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'",
                                },
                              ],
                              attrs: {
                                size: "large",
                                placeholder: "Título obtenido",
                                name: "name_title_basic",
                              },
                              model: {
                                value:
                                  _vm.formAcademicReferencesBasic.name_title,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formAcademicReferencesBasic,
                                    "name_title",
                                    $$v
                                  )
                                },
                                expression:
                                  "formAcademicReferencesBasic.name_title",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "vx-col md:w-1/4 w-full" },
                          [
                            _c(
                              "label",
                              {
                                style: _vm.colorLabel(
                                  _vm.errors.has("date_graduation_basic")
                                ),
                              },
                              [_vm._v("Fecha de graduación*")]
                            ),
                            _c("datepicker", {
                              attrs: {
                                placeholder: "Fecha de graduación",
                                name: "date_graduation_basic",
                              },
                              model: {
                                value:
                                  _vm.formAcademicReferencesBasic
                                    .date_graduation,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formAcademicReferencesBasic,
                                    "date_graduation",
                                    $$v
                                  )
                                },
                                expression:
                                  "formAcademicReferencesBasic.date_graduation",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "vx-col md:w-1/4 w-full" },
                          [
                            _c(
                              "label",
                              {
                                style: _vm.colorLabel(
                                  _vm.errors.has("city_id_basic")
                                ),
                              },
                              [_vm._v("Ciudad*")]
                            ),
                            _c("v-select", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'",
                                },
                              ],
                              attrs: {
                                options: _vm.cities(),
                                name: "city_id_basic",
                              },
                              model: {
                                value: _vm.formAcademicReferencesBasic.city_id,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formAcademicReferencesBasic,
                                    "city_id",
                                    $$v
                                  )
                                },
                                expression:
                                  "formAcademicReferencesBasic.city_id",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c(
                        "vs-row",
                        { staticClass: "row-btn-save" },
                        [
                          _c(
                            "vs-col",
                            {
                              attrs: {
                                "vs-offset": "10",
                                "vs-type": "flex",
                                "vs-justify": "center",
                                "vs-align": "center",
                                "vs-w": "2",
                              },
                            },
                            [
                              _c(
                                "vs-button",
                                {
                                  staticClass: "mt-5 block",
                                  attrs: { type: "filled" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.submitFormAcademicReferencesBasic.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [_vm._v("Agregar")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "vx-row" }, [
                    _c(
                      "div",
                      { staticClass: "vx-col w-full lg:w-1/1 mb-base" },
                      [
                        _c(
                          "table",
                          { staticClass: "table-references" },
                          [
                            _c("tr", { staticClass: "row-table" }, [
                              _c("th", { staticClass: "row-content" }, [
                                _vm._v("Nombre institución"),
                              ]),
                              _c("th", { staticClass: "row-content" }, [
                                _vm._v("Título"),
                              ]),
                              _c("th", { staticClass: "row-content" }, [
                                _vm._v("Fecha de graduación"),
                              ]),
                            ]),
                            _vm._l(
                              _vm.academic_references_basic,
                              function (academic_reference, index) {
                                return _c(
                                  "tr",
                                  { key: index, staticClass: "row-table" },
                                  [
                                    _c("td", { staticClass: "row-content" }, [
                                      _vm._v(
                                        _vm._s(
                                          academic_reference.name_institution
                                        )
                                      ),
                                    ]),
                                    _c("td", { staticClass: "row-content" }, [
                                      _vm._v(
                                        _vm._s(academic_reference.name_title)
                                      ),
                                    ]),
                                    _c("td", { staticClass: "row-content" }, [
                                      _vm._v(
                                        _vm._s(
                                          academic_reference.date_graduation
                                        )
                                      ),
                                    ]),
                                  ]
                                )
                              }
                            ),
                          ],
                          2
                        ),
                      ]
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "tab-content",
                {
                  staticClass: "mb-5",
                  attrs: {
                    title: "Estudios superiores",
                    icon: "feather icon-book",
                    "before-change": _vm.validatesAcademicReferencesSuperior,
                  },
                },
                [
                  _c(
                    "vs-row",
                    { staticClass: "title" },
                    [
                      _c(
                        "vs-col",
                        {
                          attrs: {
                            "vs-type": "flex",
                            "vs-justify": "center",
                            "vs-align": "center",
                            "vs-w": "6",
                          },
                        },
                        [
                          _c("h3", [
                            _vm._v(
                              "\n              Estudios superiores\n            "
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c("br"),
                  _c(
                    "vs-row",
                    [
                      _c(
                        "vs-col",
                        {
                          attrs: {
                            "vs-type": "flex",
                            "vs-justify": "center",
                            "vs-align": "center",
                            "vs-w": "12",
                          },
                        },
                        [
                          _vm._v(
                            "\n            Compártenos tus dos últimos estudios terminados: técnica, tecnología, pregrado, posgrado (no aplican cursos cortos).\n          "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c("br"),
                  _c(
                    "form",
                    [
                      _c("div", { staticClass: "vx-row" }, [
                        _c(
                          "div",
                          { staticClass: "vx-col md:w-1/4 w-full" },
                          [
                            _c(
                              "label",
                              {
                                style: _vm.colorLabel(
                                  _vm.errors.has("name_institution")
                                ),
                              },
                              [_vm._v("Nombre institución*")]
                            ),
                            _c("vs-input", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'",
                                },
                              ],
                              attrs: {
                                size: "large",
                                placeholder: "Nombre institución",
                                name: "name_institution",
                              },
                              model: {
                                value:
                                  _vm.formAcademicReferencesSuperior
                                    .name_institution,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formAcademicReferencesSuperior,
                                    "name_institution",
                                    $$v
                                  )
                                },
                                expression:
                                  "formAcademicReferencesSuperior.name_institution",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "vx-col md:w-1/4 w-full" },
                          [
                            _c(
                              "label",
                              {
                                style: _vm.colorLabel(
                                  _vm.errors.has("name_title")
                                ),
                              },
                              [_vm._v("Título obtenido*")]
                            ),
                            _c("vs-input", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'",
                                },
                              ],
                              attrs: {
                                size: "large",
                                placeholder: "Título obtenido",
                                name: "name_title",
                              },
                              model: {
                                value:
                                  _vm.formAcademicReferencesSuperior.name_title,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formAcademicReferencesSuperior,
                                    "name_title",
                                    $$v
                                  )
                                },
                                expression:
                                  "formAcademicReferencesSuperior.name_title",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "vx-col md:w-1/4 w-full" },
                          [
                            _c(
                              "label",
                              {
                                style: _vm.colorLabel(
                                  _vm.errors.has("date_graduation")
                                ),
                              },
                              [_vm._v("Fecha de graduación*")]
                            ),
                            _c("datepicker", {
                              attrs: {
                                placeholder: "Fecha de graduación",
                                name: "date_graduation",
                              },
                              model: {
                                value:
                                  _vm.formAcademicReferencesSuperior
                                    .date_graduation,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formAcademicReferencesSuperior,
                                    "date_graduation",
                                    $$v
                                  )
                                },
                                expression:
                                  "formAcademicReferencesSuperior.date_graduation",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "vx-col md:w-1/4 w-full" },
                          [
                            _c(
                              "label",
                              {
                                style: _vm.colorLabel(
                                  _vm.errors.has("city_id")
                                ),
                              },
                              [_vm._v("Ciudad*")]
                            ),
                            _c("v-select", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'",
                                },
                              ],
                              attrs: { options: _vm.cities(), name: "city_id" },
                              model: {
                                value:
                                  _vm.formAcademicReferencesSuperior.city_id,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formAcademicReferencesSuperior,
                                    "city_id",
                                    $$v
                                  )
                                },
                                expression:
                                  "formAcademicReferencesSuperior.city_id",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c(
                        "vs-row",
                        { staticClass: "row-btn-save" },
                        [
                          _c(
                            "vs-col",
                            {
                              attrs: {
                                "vs-offset": "10",
                                "vs-type": "flex",
                                "vs-justify": "center",
                                "vs-align": "center",
                                "vs-w": "2",
                              },
                            },
                            [
                              _c(
                                "vs-button",
                                {
                                  staticClass: "mt-5 block",
                                  attrs: { type: "filled" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.submitFormAcademicReferencesSuperior.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [_vm._v("Agregar")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "vx-row" }, [
                    _c(
                      "div",
                      { staticClass: "vx-col w-full lg:w-1/1 mb-base" },
                      [
                        _c(
                          "table",
                          { staticClass: "table-references" },
                          [
                            _c("tr", { staticClass: "row-table" }, [
                              _c("th", { staticClass: "row-content" }, [
                                _vm._v("Nombre institución"),
                              ]),
                              _c("th", { staticClass: "row-content" }, [
                                _vm._v("Título"),
                              ]),
                              _c("th", { staticClass: "row-content" }, [
                                _vm._v("Fecha de graduación"),
                              ]),
                            ]),
                            _vm._l(
                              _vm.academic_references_superior,
                              function (academic_reference, index) {
                                return _c(
                                  "tr",
                                  { key: index, staticClass: "row-table" },
                                  [
                                    _c("td", { staticClass: "row-content" }, [
                                      _vm._v(
                                        _vm._s(
                                          academic_reference.name_institution
                                        )
                                      ),
                                    ]),
                                    _c("td", { staticClass: "row-content" }, [
                                      _vm._v(
                                        _vm._s(academic_reference.name_title)
                                      ),
                                    ]),
                                    _c("td", { staticClass: "row-content" }, [
                                      _vm._v(
                                        _vm._s(
                                          academic_reference.date_graduation
                                        )
                                      ),
                                    ]),
                                  ]
                                )
                              }
                            ),
                          ],
                          2
                        ),
                      ]
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "tab-content",
                {
                  staticClass: "mb-5",
                  attrs: {
                    title: "Referencias laborales",
                    icon: "feather icon-briefcase",
                    "before-change": _vm.validatesLaboralReferences,
                  },
                },
                [
                  _c(
                    "vs-row",
                    { staticClass: "title" },
                    [
                      _c(
                        "vs-col",
                        {
                          attrs: {
                            "vs-type": "flex",
                            "vs-justify": "center",
                            "vs-align": "center",
                            "vs-w": "6",
                          },
                        },
                        [
                          _c("h3", [
                            _vm._v(
                              "\n              Referencias laborales oka\n            "
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c("br"),
                  _c(
                    "vs-row",
                    [
                      _c(
                        "vs-col",
                        {
                          attrs: {
                            "vs-type": "flex",
                            "vs-justify": "center",
                            "vs-align": "center",
                            "vs-w": "12",
                          },
                        },
                        [
                          _vm._v(
                            "\n            Ingresa los datos de tus últimas dos experiencias laborales.\n          "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c("br"),
                  _c(
                    "form",
                    [
                      _c("div", { staticClass: "vx-row" }, [
                        _c(
                          "div",
                          { staticClass: "vx-col md:w-1/4 w-full" },
                          [
                            _c(
                              "label",
                              {
                                style: _vm.colorLabel(
                                  _vm.errors.has("company_name")
                                ),
                              },
                              [_vm._v("Empresa*")]
                            ),
                            _c("vs-input", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'",
                                },
                              ],
                              attrs: {
                                size: "large",
                                placeholder: "Empresa",
                                name: "company_name",
                              },
                              model: {
                                value: _vm.formLaboralReferences.company_name,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formLaboralReferences,
                                    "company_name",
                                    $$v
                                  )
                                },
                                expression:
                                  "formLaboralReferences.company_name",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "vx-col md:w-1/4 w-full" },
                          [
                            _c(
                              "label",
                              {
                                style: _vm.colorLabel(_vm.errors.has("charge")),
                              },
                              [_vm._v("Cargo desempeñado*")]
                            ),
                            _c("vs-input", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'",
                                },
                              ],
                              attrs: {
                                size: "large",
                                placeholder: "Cargo desempeñado",
                                name: "charge",
                              },
                              model: {
                                value: _vm.formLaboralReferences.charge,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formLaboralReferences,
                                    "charge",
                                    $$v
                                  )
                                },
                                expression: "formLaboralReferences.charge",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "vx-col md:w-1/4 w-full" },
                          [
                            _c(
                              "label",
                              {
                                style: _vm.colorLabel(
                                  _vm.errors.has("start_at")
                                ),
                              },
                              [_vm._v("Fecha inicial*")]
                            ),
                            _c("datepicker", {
                              attrs: {
                                placeholder: "Fecha inicial",
                                name: "start_at",
                              },
                              model: {
                                value: _vm.formLaboralReferences.start_at,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formLaboralReferences,
                                    "start_at",
                                    $$v
                                  )
                                },
                                expression: "formLaboralReferences.start_at",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "vx-col md:w-1/4 w-full" },
                          [
                            _c(
                              "label",
                              {
                                style: _vm.colorLabel(
                                  _vm.errors.has("leave_at")
                                ),
                              },
                              [_vm._v("Fecha final*")]
                            ),
                            _c("datepicker", {
                              attrs: {
                                placeholder: "Fecha final",
                                name: "leave_at",
                              },
                              model: {
                                value: _vm.formLaboralReferences.leave_at,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formLaboralReferences,
                                    "leave_at",
                                    $$v
                                  )
                                },
                                expression: "formLaboralReferences.leave_at",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "vx-row" }, [
                        _c(
                          "div",
                          { staticClass: "vx-col md:w-1/4 w-full" },
                          [
                            _c(
                              "label",
                              {
                                style: _vm.colorLabel(
                                  _vm.errors.has("contact_name")
                                ),
                              },
                              [_vm._v("Nombre contacto*")]
                            ),
                            _c("vs-input", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'",
                                },
                              ],
                              attrs: {
                                size: "large",
                                placeholder: "Nombre contacto",
                                name: "contact_name",
                              },
                              model: {
                                value: _vm.formLaboralReferences.contact_name,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formLaboralReferences,
                                    "contact_name",
                                    $$v
                                  )
                                },
                                expression:
                                  "formLaboralReferences.contact_name",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "vx-col md:w-1/4 w-full" },
                          [
                            _c(
                              "label",
                              {
                                style: _vm.colorLabel(
                                  _vm.errors.has("contact_charge")
                                ),
                              },
                              [_vm._v("Cargo del contacto contacto*")]
                            ),
                            _c("vs-input", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'",
                                },
                              ],
                              attrs: {
                                size: "large",
                                placeholder: "Cargo del contacto",
                                name: "contact_charge",
                              },
                              model: {
                                value: _vm.formLaboralReferences.contact_charge,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formLaboralReferences,
                                    "contact_charge",
                                    $$v
                                  )
                                },
                                expression:
                                  "formLaboralReferences.contact_charge",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "vx-col md:w-1/4 w-full" },
                          [
                            _c(
                              "label",
                              {
                                style: _vm.colorLabel(
                                  _vm.errors.has("contact_telephone")
                                ),
                              },
                              [_vm._v("Teléfono del contacto*")]
                            ),
                            _c("vs-input", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'",
                                },
                              ],
                              attrs: {
                                size: "large",
                                placeholder: "Teléfono del contacto",
                                name: "contact_telephone",
                              },
                              model: {
                                value:
                                  _vm.formLaboralReferences.contact_telephone,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formLaboralReferences,
                                    "contact_telephone",
                                    $$v
                                  )
                                },
                                expression:
                                  "formLaboralReferences.contact_telephone",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "vx-col md:w-1/4 w-full" },
                          [
                            _c(
                              "label",
                              {
                                style: _vm.colorLabel(_vm.errors.has("salary")),
                              },
                              [_vm._v("Email*")]
                            ),
                            _c("vs-input", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'",
                                },
                              ],
                              attrs: {
                                size: "large",
                                placeholder: "Email",
                                name: "salary",
                              },
                              model: {
                                value: _vm.formLaboralReferences.email,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formLaboralReferences,
                                    "email",
                                    $$v
                                  )
                                },
                                expression: "formLaboralReferences.email",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "vx-row" }, [
                        _c(
                          "div",
                          { staticClass: "vx-col md:w-1/4 w-full" },
                          [
                            _c(
                              "label",
                              {
                                style: _vm.colorLabel(_vm.errors.has("salary")),
                              },
                              [_vm._v("Última asignación salarial*")]
                            ),
                            _c("vs-input", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'",
                                },
                              ],
                              attrs: {
                                size: "large",
                                placeholder: "Última asignación salarial",
                                name: "salary",
                              },
                              model: {
                                value: _vm.formLaboralReferences.salary,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formLaboralReferences,
                                    "salary",
                                    $$v
                                  )
                                },
                                expression: "formLaboralReferences.salary",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "vx-col md:w-1/4 w-full" },
                          [
                            _c(
                              "label",
                              {
                                style: _vm.colorLabel(
                                  _vm.errors.has("contract")
                                ),
                              },
                              [_vm._v("Tipo de contrato*")]
                            ),
                            _c(
                              "vs-select",
                              {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required",
                                    expression: "'required'",
                                  },
                                ],
                                staticClass:
                                  "w-full select-large mt-5 select-simple",
                                attrs: { name: "contract" },
                                model: {
                                  value: _vm.formLaboralReferences.contract,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formLaboralReferences,
                                      "contract",
                                      $$v
                                    )
                                  },
                                  expression: "formLaboralReferences.contract",
                                },
                              },
                              _vm._l(_vm.typeContracts, function (item, index) {
                                return _c("vs-select-item", {
                                  key: index,
                                  attrs: { value: item.id, text: item.label },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "vx-col md:w-1/4 w-full" },
                          [
                            _c(
                              "label",
                              {
                                style: _vm.colorLabel(
                                  _vm.errors.has("retirement")
                                ),
                              },
                              [_vm._v("Motivo de retiro*")]
                            ),
                            _c("vs-input", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'",
                                },
                              ],
                              attrs: {
                                size: "large",
                                placeholder: "Motivo de retiro",
                                name: "retirement",
                              },
                              model: {
                                value: _vm.formLaboralReferences.retirement,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formLaboralReferences,
                                    "retirement",
                                    $$v
                                  )
                                },
                                expression: "formLaboralReferences.retirement",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c(
                        "vs-row",
                        { staticClass: "row-btn-save" },
                        [
                          _c(
                            "vs-col",
                            {
                              attrs: {
                                "vs-offset": "10",
                                "vs-type": "flex",
                                "vs-justify": "center",
                                "vs-align": "center",
                                "vs-w": "2",
                              },
                            },
                            [
                              _c(
                                "vs-button",
                                {
                                  staticClass: "mt-5 block",
                                  attrs: { type: "filled" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.submitFormLaboralReferences.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [_vm._v("Agregar")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "vx-row" }, [
                    _c(
                      "div",
                      { staticClass: "vx-col w-full lg:w-1/1 mb-base" },
                      [
                        _c(
                          "table",
                          { staticClass: "table-references" },
                          [
                            _c("tr", { staticClass: "row-table" }, [
                              _c("th", { staticClass: "row-content" }, [
                                _vm._v("Empresa"),
                              ]),
                              _c("th", { staticClass: "row-content" }, [
                                _vm._v("Cargo desempeñado"),
                              ]),
                              _c("th", { staticClass: "row-content" }, [
                                _vm._v("Fecha inicial"),
                              ]),
                              _c("th", { staticClass: "row-content" }, [
                                _vm._v("Fecha final"),
                              ]),
                              _c("th", { staticClass: "row-content" }, [
                                _vm._v("Contacto"),
                              ]),
                              _c("th", { staticClass: "row-content" }, [
                                _vm._v("Cargo del contacto"),
                              ]),
                            ]),
                            _vm._l(
                              _vm.laboral_references,
                              function (laboral_reference, index) {
                                return _c(
                                  "tr",
                                  { key: index, staticClass: "row-table" },
                                  [
                                    _c("td", { staticClass: "row-content" }, [
                                      _vm._v(
                                        _vm._s(laboral_reference.company_name)
                                      ),
                                    ]),
                                    _c("td", { staticClass: "row-content" }, [
                                      _vm._v(_vm._s(laboral_reference.charge)),
                                    ]),
                                    _c("td", { staticClass: "row-content" }, [
                                      _vm._v(
                                        _vm._s(laboral_reference.start_at)
                                      ),
                                    ]),
                                    _c("td", { staticClass: "row-content" }, [
                                      _vm._v(
                                        _vm._s(laboral_reference.leave_at)
                                      ),
                                    ]),
                                    _c("td", { staticClass: "row-content" }, [
                                      _vm._v(
                                        _vm._s(laboral_reference.contact_name)
                                      ),
                                    ]),
                                    _c("td", { staticClass: "row-content" }, [
                                      _vm._v(
                                        _vm._s(laboral_reference.contact_charge)
                                      ),
                                    ]),
                                  ]
                                )
                              }
                            ),
                          ],
                          2
                        ),
                      ]
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }