<template>
  <div class="basic-information">
    <vs-row class="title">
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
        <h3>
          {{titleForm}}
        </h3>
      </vs-col>
    </vs-row>
    <br> 
    <vs-row>
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
        Ingresa los datos de tus últimas dos experiencias laborales.
      </vs-col>
    </vs-row>       
    <br>
    <form>

      <div class="vx-row">              
        <div class="vx-col md:w-1/4 w-full">
          <label v-bind:style="colorLabel(errors.has('company_name'))">Empresa*</label>
          <vs-input size="large" v-validate="'required'" placeholder="Empresa" name="company_name" v-model="form.company_name" />          
        </div>
        <div class="vx-col md:w-1/4 w-full">
          <label v-bind:style="colorLabel(errors.has('charge'))">Cargo desempeñado*</label>
          <vs-input size="large" v-validate="'required'" placeholder="Cargo desempeñado" name="charge" v-model="form.charge" />
        </div>
        <div class="vx-col md:w-1/4 w-full">
          <label v-bind:style="colorLabel(errors.has('start_at'))">Fecha inicial*</label>
          <datepicker placeholder="Fecha inicial" name="start_at" v-model="form.start_at"></datepicker>
        </div>                                      
        <div class="vx-col md:w-1/4 w-full">
          <label v-bind:style="colorLabel(errors.has('leave_at'))">Fecha final*</label>
          <datepicker placeholder="Fecha final" name="leave_at" v-model="form.leave_at"></datepicker>
        </div>                              
      </div>      

      <div class="vx-row">              
        <div class="vx-col md:w-1/4 w-full">
          <label v-bind:style="colorLabel(errors.has('contact_name'))">Nombre contacto*</label>
          <vs-input size="large" v-validate="'required'" placeholder="Nombre contacto" name="contact_name" v-model="form.contact_name" />          
        </div>
        <div class="vx-col md:w-1/4 w-full">
          <label v-bind:style="colorLabel(errors.has('contact_charge'))">Cargo del contacto contacto*</label>
          <vs-input size="large" v-validate="'required'" placeholder="Cargo del contacto" name="contact_charge" v-model="form.contact_charge" />          
        </div>
        <div class="vx-col md:w-1/4 w-full">
          <label v-bind:style="colorLabel(errors.has('contact_telephone'))">Teléfono del contacto*</label>
          <vs-input size="large" v-validate="'required'" placeholder="Teléfono del contacto" name="contact_telephone" v-model="form.contact_telephone" />          
        </div>        
        <div class="vx-col md:w-1/4 w-full">
          <label v-bind:style="colorLabel(errors.has('salary'))">Email*</label>
          <vs-input size="large" v-validate="'required'" placeholder="Email" name="salary" v-model="form.email" />          
        </div>        
      </div>            

      <div class="vx-row">              
        <div class="vx-col md:w-1/4 w-full">
          <label v-bind:style="colorLabel(errors.has('salary'))">Última asignación salarial*</label>
          <vs-input size="large" v-validate="'required'" placeholder="Última asignación salarial" name="salary" v-model="form.salary" />          
        </div>
        <div class="vx-col md:w-1/4 w-full">
          <label v-bind:style="colorLabel(errors.has('contract'))">Tipo de contrato*</label>
          <vs-select v-model="form.contract" class="w-full select-large mt-5 select-simple" name="contract" v-validate="'required'">
            <vs-select-item :key="index" :value="item.id" :text="item.label" v-for="(item,index) in typeContracts" />
          </vs-select>                          
        </div>
        <div class="vx-col md:w-1/4 w-full">
          <label v-bind:style="colorLabel(errors.has('calls'))">Llamados de atención*</label>
          <vs-input type="number" size="large" v-validate="'required'" placeholder="Llamados de atención" name="calls" v-model="form.calls" />          
        </div>
        <div class="vx-col md:w-1/4 w-full">
          <label v-bind:style="colorLabel(errors.has('retirement'))">Motivo de retiro*</label>
          <vs-input size="large" v-validate="'required'" placeholder="Motivo de retiro" name="retirement" v-model="form.retirement" />          
        </div>                
      </div>                  
      <!-- button sumit -->
      <vs-row class="row-btn-save">
        <vs-col vs-offset="10"  vs-type="flex" vs-justify="center" vs-align="center" vs-w="2">
          <vs-button type="filled" @click.prevent="submitForm" class="mt-5 block">Guardar</vs-button>
        </vs-col>
      </vs-row>      
    </form>
    <div class="vx-row">
      <div class="vx-col w-full lg:w-1/1 mb-base">
        <table class="table-references">
          <tr class="row-table">
            <th class="row-content">Empresa</th>
            <th class="row-content">Cargo desempeñado</th>
            <th class="row-content">Fecha inicial</th>
            <th class="row-content">Fecha final</th>
            <th class="row-content">Contacto</th>
            <th class="row-content">Cargo del contacto</th>            
          </tr>
          <tr class="row-table" v-for="(laboral_reference, index) in laboral_references" :key="index">
            <td class="row-content">{{laboral_reference.company_name}}</td>
            <td class="row-content">{{laboral_reference.charge}}</td>
            <td class="row-content">{{laboral_reference.start_at}}</td>
            <td class="row-content">{{laboral_reference.leave_at}}</td>
            <td class="row-content">{{laboral_reference.contact_name}}</td>
            <td class="row-content">{{laboral_reference.contact_charge}}</td>
          </tr>
        </table>
      </div>
    </div>                    
  </div>
</template>

<script>
import vSelect from 'vue-select';
import Datepicker from 'vuejs-datepicker';
import cities from '../../../mixins/cities.js'
import api from '../../../mixins/api.js'
export default {
  props: {
    candidate: Object
  },    
  mixins: [cities, api],
  data(){
    return{
      laboral_references: [],
      typeContracts: [
        {id: 0, label: 'Indefinido'},
        {id: 1, label: 'Fijo'},
        {id: 2, label: 'Obra labor'},
        {id: 3, label: 'Prestación de servicios'},
        {id: 5, label: 'Freelancer'},
        {id: 6, label: 'Aprendiz'},
        {id: 7, label: 'Practicante'},
        {id: 4, label: 'Otro'},
      ],
      idTypes: [
        {id: null, label: 'Seleccione una opción'},
        {id: 1, label: 'Cédula'},
        {id: 2, label: 'Tarjeta de identidad'},
        {id: 3, label: 'Pasaporte'},
        {id: 4, label: 'Cédula de extranjería'},
      ],        
      genders: [
        {id: null, label: 'Seleccione una opción'},
        {id: 0, label: 'Femenino'},
        {id: 1, label: 'Masculino'}
      ],
      civilTypes: [
        {id: null, label: 'Seleccione una opción'},
        {id: 0, label: 'Soltero/a'},
        {id: 1, label: 'Casado/a'},
        {id: 3, label: 'Viudo/a'},
        {id: 4, label: 'Unión libre'}
      ],
      bloodTypes: [
        {id: null, label: 'Seleccione una opción'},
        {id: 0, label: 'O-'},
        {id: 0, label: 'O+'},
        {id: 0, label: 'A-'},
        {id: 0, label: 'A+'},
        {id: 0, label: 'B-'},
        {id: 0, label: 'B+'},
        {id: 0, label: 'AB-'},
        {id: 0, label: 'AB+'}
      ],      
      titleForm: 'Referencias laborales',
      form: {
        company_name: 'Carrefour', // add
        charge: 'Auxiliar', // add
        start_at: '', // add
        leave_at: '', // add
        contact_name: 'Carlos Ferrer', // add
        contact_charge: 'Supervisor', // add
        contact_telephone: '4234234', // add 
        salary: '343', // add
        contract: '', // add
        calls: '1', // add
        retirement: 'mejora laboral', // add
        email: 'luis.torres784@gmail.com',
        candidate_id: ''
      }
    }
  },
  methods: {
    colorLabel(errorLabel){
      var cadenaColor = 'color: black';
      if(errorLabel){
        cadenaColor = 'color: red';
      }
      return cadenaColor;
    },
    submitForm() {
      this.$validator.validateAll().then(result => {
        if(result) {
          var candidate_id = this.candidate.id
          this.form.candidate_id = candidate_id
          var path = "api/v1/laboral_references"
          console.log("this.form ", JSON.stringify(this.form))
          this.requestPost(this.url()+path, this.form).then(response => {
            if(response.status == 201){
              var laboral_reference = response.data
              this.laboral_references.push(laboral_reference)
              this.$vs.notify({color:'success',title:'Referencia laboral creada',text:'Se ha creado la referencia laboral', position: 'bottom-left'})          
            }else{
              this.$vs.notify({color:'danger',title:'Ups',text:'No se creo la referencia laboral', position: 'bottom-left'})      
            }
          }, (err) => {
            console.log("err ", err)
            this.$vs.notify({color:'danger',title:'Ups',text:'No se creo la referencia laboral', position: 'bottom-left'})      
          })          
        }else{
          this.$vs.notify({color:'danger',title:'Ups',text:'No se creo la referencia laboral', position: 'bottom-left'})      
        }
      })
    }        
  },
  created() {
    this.laboral_references = this.candidate.laboral_references
  },        
  components: {
    vSelect,
    Datepicker
  }

}
</script>

<style>
  /* .basic-information{
    background: #ffffff;
    margin-left: 5rem;
    margin-right: 5rem;
    border-radius: 10px;
    height: 42rem;
  } */
  .title{
    padding-top: 1rem;
  }
  .row-btn-save{
    padding-bottom: 1rem;
  }
  .message-field-require{
    font-size: 2rem !important;
  }
  .vs__search{
    height: 38px !important
  }
  .select-simple{
    margin-top: 0rem !important;
  }
  .select-large input.vs-select--input{
    height: 49px !important;
  }
  .vdp-datepicker input{
    height: 49px !important;
  }
  .vx-row{
    margin: 0;
    padding-top: 1rem;
  }
  .vs-con-input-label{
    width: 100%;
  }
  .vdp-datepicker input{
    width: 100%;
  }
</style>