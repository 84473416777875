var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "basic-information" },
    [
      _c(
        "vs-row",
        { staticClass: "title" },
        [
          _c(
            "vs-col",
            {
              attrs: {
                "vs-type": "flex",
                "vs-justify": "center",
                "vs-align": "center",
                "vs-w": "6",
              },
            },
            [
              _c("h3", [
                _vm._v("\n        " + _vm._s(_vm.titleForm) + "\n      "),
              ]),
            ]
          ),
        ],
        1
      ),
      _c("br"),
      _c(
        "vs-row",
        [
          _c(
            "vs-col",
            {
              attrs: {
                "vs-type": "flex",
                "vs-justify": "center",
                "vs-align": "center",
                "vs-w": "12",
              },
            },
            [
              _vm._v(
                "\n      Compártenos tus dos últimos estudios terminados: técnica, tecnología, pregrado, posgrado (no aplican cursos cortos).\n    "
              ),
            ]
          ),
        ],
        1
      ),
      _c("br"),
      _c(
        "form",
        [
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-1/4 w-full" },
              [
                _c(
                  "label",
                  { style: _vm.colorLabel(_vm.errors.has("name_institution")) },
                  [_vm._v("Nombre institución*")]
                ),
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  attrs: {
                    size: "large",
                    placeholder: "Nombre institución",
                    name: "name_institution",
                  },
                  model: {
                    value: _vm.form.name_institution,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "name_institution", $$v)
                    },
                    expression: "form.name_institution",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/4 w-full" },
              [
                _c(
                  "label",
                  { style: _vm.colorLabel(_vm.errors.has("name_title")) },
                  [_vm._v("Título obtenido*")]
                ),
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  attrs: {
                    size: "large",
                    placeholder: "Título obtenido",
                    name: "name_title",
                  },
                  model: {
                    value: _vm.form.name_title,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "name_title", $$v)
                    },
                    expression: "form.name_title",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/4 w-full" },
              [
                _c(
                  "label",
                  { style: _vm.colorLabel(_vm.errors.has("date_graduation")) },
                  [_vm._v("Fecha de graduación*")]
                ),
                _c("datepicker", {
                  attrs: {
                    placeholder: "Fecha de graduación",
                    name: "date_graduation",
                  },
                  model: {
                    value: _vm.form.date_graduation,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "date_graduation", $$v)
                    },
                    expression: "form.date_graduation",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/4 w-full" },
              [
                _c(
                  "label",
                  { style: _vm.colorLabel(_vm.errors.has("city_id")) },
                  [_vm._v("Ciudad*")]
                ),
                _c("v-select", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  attrs: { options: _vm.cities(), name: "city_id" },
                  model: {
                    value: _vm.form.city_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "city_id", $$v)
                    },
                    expression: "form.city_id",
                  },
                }),
              ],
              1
            ),
          ]),
          _c(
            "vs-row",
            { staticClass: "row-btn-save" },
            [
              _c(
                "vs-col",
                {
                  attrs: {
                    "vs-offset": "10",
                    "vs-type": "flex",
                    "vs-justify": "center",
                    "vs-align": "center",
                    "vs-w": "2",
                  },
                },
                [
                  _c(
                    "vs-button",
                    {
                      staticClass: "mt-5 block",
                      attrs: { type: "filled" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.submitForm.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("Guardar")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "vx-row" }, [
        _c("div", { staticClass: "vx-col w-full lg:w-1/1 mb-base" }, [
          _c(
            "table",
            { staticClass: "table-references" },
            [
              _vm._m(0),
              _vm._l(
                _vm.academic_references,
                function (academic_reference, index) {
                  return _c("tr", { key: index, staticClass: "row-table" }, [
                    _c("td", { staticClass: "row-content" }, [
                      _vm._v(_vm._s(academic_reference.name_institution)),
                    ]),
                    _c("td", { staticClass: "row-content" }, [
                      _vm._v(_vm._s(academic_reference.name_title)),
                    ]),
                    _c("td", { staticClass: "row-content" }, [
                      _vm._v(_vm._s(academic_reference.date_graduation)),
                    ]),
                  ])
                }
              ),
            ],
            2
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticClass: "row-table" }, [
      _c("th", { staticClass: "row-content" }, [_vm._v("Nombre institución")]),
      _c("th", { staticClass: "row-content" }, [_vm._v("Título")]),
      _c("th", { staticClass: "row-content" }, [_vm._v("Fecha de graduación")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }