<template>
  <div class="basic-information">
    <vs-row class="title">
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
        <h3>
          {{titleForm}}
        </h3>
      </vs-col>
    </vs-row>
    <br> 
    <vs-row>
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
        Compártenos tus dos últimos estudios terminados: técnica, tecnología, pregrado, posgrado (no aplican cursos cortos).
      </vs-col>
    </vs-row>       
    <br>
    <form>

      <div class="vx-row">              
        <div class="vx-col md:w-1/4 w-full">
          <label v-bind:style="colorLabel(errors.has('name_institution'))">Nombre institución*</label>
          <vs-input size="large" v-validate="'required'" placeholder="Nombre institución" name="name_institution" v-model="form.name_institution" />          
        </div>
        <div class="vx-col md:w-1/4 w-full">
          <label v-bind:style="colorLabel(errors.has('name_title'))">Título obtenido*</label>
          <vs-input size="large" v-validate="'required'" placeholder="Título obtenido" name="name_title" v-model="form.name_title" />
        </div>
        <div class="vx-col md:w-1/4 w-full">
          <label v-bind:style="colorLabel(errors.has('date_graduation'))">Fecha de graduación*</label>
          <datepicker placeholder="Fecha de graduación" name="date_graduation" v-model="form.date_graduation"></datepicker>
        </div>                                      
        <div class="vx-col md:w-1/4 w-full">                    
          <label v-bind:style="colorLabel(errors.has('city_id'))">Ciudad*</label>
          <v-select v-model="form.city_id" :options="cities()" v-validate="'required'" name="city_id" ></v-select>                                        
        </div>                              
      </div> 

      <!-- button sumit -->
      <vs-row class="row-btn-save">
        <vs-col vs-offset="10" vs-type="flex" vs-justify="center" vs-align="center" vs-w="2">
          <vs-button type="filled" @click.prevent="submitForm" class="mt-5 block">Guardar</vs-button>
        </vs-col>
      </vs-row>      
    </form>
    <div class="vx-row">
      <div class="vx-col w-full lg:w-1/1 mb-base">
        <table class="table-references">
          <tr class="row-table">
            <th class="row-content">Nombre institución</th>
            <th class="row-content">Título</th>
            <th class="row-content">Fecha de graduación</th>
            <!-- <th class="row-content">Ciudad</th> -->
          </tr>
          <tr class="row-table" v-for="(academic_reference, index) in academic_references" :key="index">
            <td class="row-content">{{academic_reference.name_institution}}</td>
            <td class="row-content">{{academic_reference.name_title}}</td>
            <td class="row-content">{{academic_reference.date_graduation}}</td>
            <!-- <td class="row-content">{{academic_reference.city_id}}</td> -->
          </tr>
        </table>
      </div>
    </div>    
  </div>
</template>

<script>
import vSelect from 'vue-select';
import Datepicker from 'vuejs-datepicker';
import cities from '../../../mixins/cities.js'
import api from '../../../mixins/api.js'
export default {
  mixins: [cities, api],
  props: {
    candidate: Object
  },  
  data(){
    return{
      academic_references: [],
      idTypes: [
        {id: null, label: 'Seleccione una opción'},
        {id: 1, label: 'Cédula'},
        {id: 2, label: 'Tarjeta de identidad'},
        {id: 3, label: 'Pasaporte'},
        {id: 4, label: 'Cédula de extranjería'},
      ],        
      genders: [
        {id: null, label: 'Seleccione una opción'},
        {id: 0, label: 'Femenino'},
        {id: 1, label: 'Masculino'}
      ],
      civilTypes: [
        {id: null, label: 'Seleccione una opción'},
        {id: 0, label: 'Soltero/a'},
        {id: 1, label: 'Casado/a'},
        {id: 3, label: 'Viudo/a'},
        {id: 4, label: 'Unión libre'}
      ],
      bloodTypes: [
        {id: null, label: 'Seleccione una opción'},
        {id: 0, label: 'O-'},
        {id: 0, label: 'O+'},
        {id: 0, label: 'A-'},
        {id: 0, label: 'A+'},
        {id: 0, label: 'B-'},
        {id: 0, label: 'B+'},
        {id: 0, label: 'AB-'},
        {id: 0, label: 'AB+'}
      ],      
      titleForm: 'Referencias académicas',
      form: {
        name_institution: '',
        name_title: '',
        date_graduation: '',
        city_id: '',
        observations: '',
        project_id: '',
        candidate_id: '',
        academic_type: 1
      }
    }
  },
  methods: {
    colorLabel(errorLabel){
      var cadenaColor = 'color: black';
      if(errorLabel){
        cadenaColor = 'color: red';
      }
      return cadenaColor;
    },
    submitForm() {
      this.$validator.validateAll().then(result => {
        if(result) {
          var candidate_id = this.candidate.id
          this.form.candidate_id = candidate_id
          this.form.city_id = this.form.city_id.id
          var path = "api/v1/academic_references"
          this.requestPost(this.url()+path, this.form).then(response => {
            if(response.status == 201){
              var academic_reference = response.data
              this.academic_references.push(academic_reference)                            
              this.$vs.notify({color:'success',title:'Referencia académica creada',text:'Se ha creado la Referencia académica', position: 'bottom-left'})          
            }else{
              this.$vs.notify({color:'danger',title:'Ups',text:'No se creo la Referencia académica', position: 'bottom-left'})      
            }
          }, (err) => {
            console.log("err ", err)
            this.$vs.notify({color:'danger',title:'Ups',text:'No se creo la Referencia académica', position: 'bottom-left'})      
          })          
        }else{
          // form have errors
        }
      })
    }    
  },
  created() {
    this.academic_references = this.candidate.academic_references_superior
  },        
  components: {
    vSelect,
    Datepicker
  }

}
</script>

<style>
  /* .basic-information{
    background: #ffffff;
    margin-left: 5rem;
    margin-right: 5rem;
    border-radius: 10px;
    height: 42rem;
  } */
  .title{
    padding-top: 1rem;
  }
  .row-btn-save{
    padding-bottom: 1rem;
  }
  .message-field-require{
    font-size: 2rem !important;
  }
  .vs__search{
    height: 38px !important
  }
  .select-simple{
    margin-top: 0rem !important;
  }
  .select-large input.vs-select--input{
    height: 49px !important;
  }
  .vdp-datepicker input{
    height: 49px !important;
  }
  .vx-row{
    margin: 0;
    padding-top: 1rem;
  }
  .vs-con-input-label{
    width: 100%;
  }
  .vdp-datepicker input{
    width: 100%;
  }
</style>